import { useCallback, useMemo } from 'react';

import * as Meta from '@Queries/Meta';
import UILink from '@UI/Link';
import { isAbsoluteLink } from '@Utils/isAbsoluteLink';
import useNavigation from '@Navigation/useNavigation';
import transformUrl from '@Navigation/transformUrl';
import useRequest from '@Hooks/useRequest';
import useDeps from '@Contexts/DI/useDeps';

import type { FC, MouseEvent } from 'react';
import type { LinkProps as UILinkProps } from '@UI/Link';
import type { Region } from '@Navigation/useNavigation';

export interface LinkProps extends UILinkProps {
  className?: string;
  region?: Region;
  language?: string;
  preventDefault?: boolean;
  to?: string;
}

const Link: FC<LinkProps> = (props) => {
  const {
    to = '',
    language,
    region,
    preventDefault,
    children,
    target,
    onClick,
    ...restProps
  } = props;
  const meta = Meta.useMeta();
  const navigation = useNavigation();
  const isExternal = isAbsoluteLink(to);
  const isPhone = to?.startsWith('tel:');
  const request = useRequest();
  const { analytics } = useDeps();

  const href = useMemo(() => {
    return transformUrl({
      url: to,
      targetRegion: region?.url.replace('/', ''),
      language: request.language.slug,
    });
  }, [region, request, to]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (window.cancelClick) {
        e.preventDefault();
        return;
      }

      if (isExternal) {
        analytics.dispatchEvent('link.external.click', { link: to });
      }

      if (isPhone) {
        analytics.dispatchEvent('link.phone.click');
      }

      if (onClick) onClick(e);

      if (preventDefault) {
        e.preventDefault();
        return;
      }

      // При соблюдении этих условий ссылки должны работать как нативные
      if (!href) return;
      if (target === '_blank') return;
      if (isExternal) return;
      if (isPhone) return;
      if (meta.data?.isTerminal) return;

      e.preventDefault();

      if (href.includes('#')) {
        const changeHash = new CustomEvent('changeHash');
        navigation.replaceUrl(href);
        window.dispatchEvent(changeHash);
        return;
      }

      navigation.openPage({ url: href, region, language });
    },
    [
      isExternal,
      isPhone,
      onClick,
      preventDefault,
      href,
      target,
      meta.data?.isTerminal,
      navigation,
      region,
      language,
      to,
      analytics,
    ],
  );

  return (
    <UILink
      {...restProps}
      to={href}
      target={isExternal ? '_blank' : target}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      onClick={handleClick}
    >
      {children}
    </UILink>
  );
};

export default Link;
