import { useQuery } from '@tanstack/react-query';

import * as ApiPromo from '@Api/Promo';
import useDeps from '@Contexts/DI/useDeps';
import useRequest from '@Hooks/useRequest';
import { useDevPanel } from '@Stores/DevPanel';

import type { UseQueryResult } from '@tanstack/react-query';
import type { MenuPromotionsData } from '@Promo/typings';

function usePromotionsForMenu(): UseQueryResult<MenuPromotionsData> {
  const { logger } = useDeps();
  const { currentDate } = useDevPanel();
  const { country, region, language } = useRequest();
  const timestamp = currentDate.getTime();

  const keys = ['menu', { country, lang: language.id, region, currentDate: timestamp }];

  const result = useQuery(
    keys,
    async () => {
      try {
        const menuPromotions = await ApiPromo.getPromotionsForMenu({
          country,
          lang: language.id,
          region,
          currentDate: timestamp,
        });

        return menuPromotions || {};
      } catch (err) {
        // Этот запрос мы считаем не критичным, если произошла ошибка,
        // то допустимо отдать пустой набор данных, чтобы сохранить доступность страницы
        logger.log(err);

        return Promise.resolve({});
      }
    },
    {
      enabled: true,
      keepPreviousData: true,
    },
  );

  return result;
}

export default usePromotionsForMenu;
