import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useRequest from '@Hooks/useRequest';
import useDeps from '@Contexts/DI/useDeps';
import useCartKeys from './useCartKeys';

import type { CartData, UpdatedCartInfo, CartProductData } from '@Types/Cart';
import type { Params } from '@Api/Cart/update';

export interface UpdateParams extends Params {
  productId: CartProductData['id'];
}

function useChangePositionCount() {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const { analytics } = useDeps();

  return useMutation<UpdatedCartInfo, Error, UpdateParams>(
    (params) => {
      const { cartPositionId, quantity, signal } = params;
      return ApiCart.update({ cartPositionId, quantity, signal });
    },
    {
      onSuccess: (data) => {
        const newCart = queryClient.setQueryData(keys, (prev: CartData) => ({
          ...prev,
          ...data.cart,
          ...data,
          newPositions: prev.newPositions,
        }));

        analytics.dispatchEvent('cart.update', {
          cart: newCart,
          addLabel: 'Изменение количества',
          removeLabel: 'Изменение количества',
        });
      },
    },
  );
}

export default useChangePositionCount;
