import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';
import useRequest from '@Hooks/useRequest';

import type { CartData } from '@Types/Cart';

export const useRemoveBonus = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  const { mutate, isLoading } = useMutation(
    ['removeBonuses'],
    async () => {
      const res = await ApiCart.sendBonus({
        'BonusPointsForm[value]': 0,
      });
      return res;
    },
    {
      onSuccess: (NewCart) => {
        queryClient.setQueriesData(keys, (prev: CartData) => {
          return {
            ...prev,
            ...NewCart,
          };
        });
      },
    },
  );

  return {
    removeBonus: mutate,
    isLoading,
  };
};

export default useRemoveBonus;
