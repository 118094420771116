import { memo, useCallback, lazy } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cn, UniversalPortal } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import useMedias from '@Hooks/useMedias';
import useRequest from '@Hooks/useRequest';
import Suspense from '@Components/Suspense';
import InputSearch from '../InputSearch';
import styles from './Search.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

const SearchPopupContainer = lazy(() => import('../SearchPopupContainer'));

export interface SearchData extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Search: FC<SearchData> = (props) => {
  const { className, ...restProps } = props;
  const { isMobileM, isDesktop } = useMedias();
  const { isOpenedSearch } = HeaderStore.useMenu();
  const { result } = SearchStore.useSearch();
  const { origin } = useRequest();

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      let сaretPos = 0;

      if (target.selectionStart) {
        сaretPos = target.selectionStart;
      }
      SearchStore.setCaretPosition(сaretPos);

      await SearchStore.search({ term: result.request });
      HeaderStore.openSearch();
    },
    [result.request],
  );

  return (
    <div
      {...restProps}
      className={cn(styles.search, className)}
      itemScope
      itemType='https://schema.org/WebSite'
    >
      <meta itemProp='url' content={origin} />
      <div
        itemProp='potentialAction'
        itemScope
        itemType='https://schema.org/SearchAction'
        className={cn(styles.inputWrapper, {
          [styles.changeable]: isMobileM,
          [styles.wrapper]: !isMobileM,
        })}
      >
        <meta itemProp='target' content={`${origin}/search?ProductSearch[name]={search}`} />
        <InputSearch className={styles.input} onClick={handleClick} />
      </div>

      <UniversalPortal condition={isDesktop}>
        <CSSTransition
          classNames={{
            enterActive: styles.enterActive,
            enterDone: styles.enterDone,
            enter: styles.enter,
            exit: styles.exit,
            exitActive: styles.exitActive,
            exitDone: styles.exitDone,
          }}
          in={isOpenedSearch}
          unmountOnExit
          timeout={200}
        >
          <div className={styles.wrapperPopup}>
            <Suspense fallback={null}>
              <SearchPopupContainer className={styles.popup} />
            </Suspense>
          </div>
        </CSSTransition>
      </UniversalPortal>
    </div>
  );
};

export default memo(Search);
