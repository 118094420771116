import { memo, useCallback, useEffect, useRef, useState } from 'react';

import usePage from '@Queries/usePage';
import useDeps from '@Contexts/DI/useDeps';

import type { FC, HTMLAttributes } from 'react';

export interface CardInViewProps extends Omit<HTMLAttributes<HTMLDivElement>, 'id'> {
  id: number | string;
  listTitle: string;
  listType?: 'default' | 'cross-sale' | 'search';
  isSingleCard?: boolean;
  position?: number;
  card: unknown;
  cardType: 'product' | 'promo' | 'category' | 'relatedProduct';
  preventHandleClick?: boolean;
  label?: string;
  targetPathname?: string;
}

const CardInView: FC<CardInViewProps> = (props) => {
  const {
    children,
    id,
    listTitle,
    listType = 'default',
    isSingleCard,
    position,
    card,
    cardType,
    preventHandleClick,
    label,
    targetPathname,
    ...restProps
  } = props;
  const [isZeroHeight, setIsZeroHeight] = useState(true);
  const [isInView, setIsInView] = useState(false);
  const [intersectionObserverRoot, setIntersectionObserverRoot] = useState<Element>(null);
  const page = usePage();
  const ref = useRef(null);
  const { analytics } = useDeps();

  const handleClickCard = useCallback(() => {
    if (window.cancelClick || preventHandleClick) return;

    analytics.dispatchEvent('card.click', {
      cardType,
      listTitle,
      listType,
      id,
      card,
      position,
      isSingleCard,
      targetPathname,
    });
  }, [
    analytics,
    card,
    cardType,
    id,
    isSingleCard,
    listTitle,
    listType,
    position,
    preventHandleClick,
    targetPathname,
  ]);

  useEffect(() => {
    const handleChangeIntersectionObserverRoot = (event: CustomEvent<{ element: Element }>) => {
      const { element } = event.detail;

      if (element) setIntersectionObserverRoot(element);
      else setIntersectionObserverRoot(null);
    };

    window.addEventListener(
      'intersectionObserver.changeRoot',
      handleChangeIntersectionObserverRoot,
    );

    return () =>
      window.removeEventListener(
        'intersectionObserver.changeRoot',
        handleChangeIntersectionObserverRoot,
      );
  }, []);

  useEffect(() => {
    const refCurrent = ref.current;

    if (!refCurrent) return;

    let resizeObserver: ResizeObserver;
    let intersectionObserver: IntersectionObserver;

    async function observe() {
      // Use native Resize Observer API since it's supported, otherwise use a ponyfill from Juggle
      if ('ResizeObserver' in window === false) {
        const module = await import('@juggle/resize-observer');
        window.ResizeObserver = module.ResizeObserver;
      }

      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        // necessary logic (setting the isZeroHeight state to false) is wrapped in window.requestAnimationFrame
        // because otherwise it is being executed many times within a single animation frame and causes
        // ResizeObserver loop limit exceeded error
        // (see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523)
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || entries.length === 0) {
            return;
          }
          const [entry] = entries;
          if (entry.target.clientHeight > 0) setIsZeroHeight(false);
        });
      });

      intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          const [entry] = entries;
          setIsInView(entry.isIntersecting);
        },
        { threshold: 0.6, ...(intersectionObserverRoot && { root: intersectionObserverRoot }) },
      );

      if (isZeroHeight) {
        resizeObserver.observe(refCurrent);
      } else {
        intersectionObserver.observe(refCurrent);
      }
    }

    observe();

    return () => {
      if (resizeObserver) resizeObserver.unobserve(refCurrent);
      if (intersectionObserver) {
        analytics.dispatchEvent('card.unsee', {
          cardType,
          id,
          listTitle,
        });
        intersectionObserver.unobserve(refCurrent);
      }
    };
  }, [analytics, cardType, id, intersectionObserverRoot, isZeroHeight, listTitle]);

  const dispatchViewNewCard = useCallback(() => {
    analytics.dispatchEvent('card.view', {
      id,
      position,
      listTitle,
      listType,
      card,
      isSingleCard,
      cardType,
      label,
    });
  }, [id, card, cardType, isSingleCard, listTitle, listType, position, label, analytics]);

  const dispatchUnseeExistingCard = useCallback(() => {
    analytics.dispatchEvent('card.unsee', {
      cardType,
      id,
      listTitle,
    });
  }, [analytics, cardType, id, listTitle]);

  useEffect(() => {
    if (isInView && !page.isFetching) {
      dispatchViewNewCard();

      return;
    }

    dispatchUnseeExistingCard();
  }, [dispatchViewNewCard, dispatchUnseeExistingCard, isInView, analytics, page.isFetching]);

  return (
    <div {...restProps} ref={ref} onClick={handleClickCard}>
      {children}
    </div>
  );
};

export default memo(CardInView);
