import { useEffect } from 'react';

import useRequest from '@Hooks/useRequest';
import useNavigation from '@Navigation/useNavigation';
import transformUrl from '@Navigation/transformUrl';

/**
 * Эффект для синхронизации регионального слага в текущем адресе с текущим регионом
 * (потребность обусловлена тем, что, например, при навигации назад после смены региона
 * пользователь попадает на страницу со старым региональным слагом)
 */
const useSyncUrl = () => {
  const { language } = useRequest();
  const { pathname, search, hash, replaceUrl } = useNavigation();

  useEffect(() => {
    const transformedUrl = transformUrl({ url: pathname, language: language.slug });

    if (transformedUrl !== pathname) replaceUrl(`${transformedUrl}${search}${hash}`);
  }, [hash, language.slug, pathname, replaceUrl, search]);
};

export default useSyncUrl;
