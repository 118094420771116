import { memo, useEffect, useRef } from 'react';
import { cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import useRequest from '@Hooks/useRequest';
import Logotype from '@Components/Logotype';
import LogotypeDivanClub from '@Components/LogotypeDivanClub';
import Link from '@Navigation/Link';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import Search from '@Layouts/elems/Search';
import UserMenu from '../UserMenu';
import Burger from '../Burger';
import styles from './HeaderTablet.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface HeaderTabletProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  compressed?: boolean;
  withShadow?: boolean;
}

const HeaderTablet: FC<HeaderTabletProps> = (props) => {
  const { className, compressed, withShadow, ...restProps } = props;
  const { isDivanClub } = useRouteMatchers();
  const headerRef = useRef(null);
  const { country } = useRequest();
  const { fixed } = HeaderStore.useMenu();
  const siteName = `divan.${country === 'RUS' ? 'ru' : 'by'}`;

  // Рассчитываем высоту шапки
  useEffect(() => {
    if (!headerRef.current) return;

    const rectHeader = headerRef.current.getBoundingClientRect();

    function calculate() {
      HeaderStore.setSizes({ contentHeight: fixed ? 51 : rectHeader.height });
    }

    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, [fixed]);

  return (
    <div
      {...restProps}
      ref={headerRef}
      className={cn(
        styles.header,
        {
          [styles.compressed]: compressed,
          [styles.withShadow]: withShadow,
        },
        className,
      )}
    >
      <div className={styles.left}>
        <Burger className={styles.burger} onClick={HeaderStore.openSideBar} />
        <Link className={styles.logotype} to='/' aria-label={siteName} data-testid='logo-link'>
          {isDivanClub ? <LogotypeDivanClub /> : <Logotype country={country} />}
        </Link>
        <div className={styles.search}>
          <Search />
        </div>
      </div>

      <UserMenu />
    </div>
  );
};

export default memo(HeaderTablet);
