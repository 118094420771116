import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

export interface ResultData {
  productCount: number;
}

export interface Result {
  ok: 'success' | 'error';
  data: ResultData;
}

async function getCompareProductsCount() {
  const url = '/backend/compare/v2/count';
  const res = await Api.queryProxi<Result>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getCompareProductsCount;
