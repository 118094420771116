import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Map, YMaps } from '@pbe/react-yandex-maps';
import { cn } from '@divlab/divanui';

import useGlobalConfig from '@Hooks/useGlobalConfig';
import useCourierPosition from '@Queries/DeliveryTracking/useCourierPosition';
import CustomPlacemark from './elems/CustomPlacemark';
import Controls from './elems/Controls';
import styles from './Map.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { OrderState, RouteState } from '@Types/DeliveryTracking';
import type { CourierPositionParams } from '@Api/DeliveryTracking/typings';

interface YandexMapProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
  route: RouteState;
}

const YandexMap: FC<YandexMapProps> = (props) => {
  const { className, order, route, ...restProps } = props;
  const [map, setMap] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const ymapsRef = useRef(null);
  const routeRef = useRef(null);
  const params: CourierPositionParams = {
    courierId: order?.courierId,
    deliveryCode: order?.deliveryCode,
    deliveryDate: order?.deliveryDate,
  };
  const { data } = useCourierPosition(params);
  const [courierCoords, setCourierCoords] = useState<[number, number]>(route?.[0]?.coordinates);

  useEffect(() => {
    if (data?.coordinates) setCourierCoords(data.coordinates);
  }, [data?.coordinates]);

  const points = useMemo(() => {
    if (!route) return [];

    const sortedPoints = route.slice().sort((a, b) => a.sequence - b.sequence);

    const warehouseIndex = sortedPoints.findIndex((point) => point.type === 'warehouse');
    const clientIndex = sortedPoints.findIndex((point) => point.type === 'current_client');

    return sortedPoints.slice(warehouseIndex, clientIndex + 1);
  }, [route]);

  const coordsWithCourier = useMemo(() => {
    const filteredCoords = courierCoords ? [courierCoords] : [];

    const unfinishedCoords = points
      .filter((point) => !point.completed && point.coordinates)
      .map((point) => point.coordinates);

    filteredCoords.push(...unfinishedCoords);

    return filteredCoords;
  }, [courierCoords, points]);

  const handleInit = useCallback(() => {
    routeRef.current = new ymapsRef.current.multiRouter.MultiRoute(
      {
        referencePoints: coordsWithCourier,
        params: {
          results: 1,
        },
      },
      {
        boundsAutoApply: coordsWithCourier.length > 1,
        routeActiveStrokeWidth: 4,
        routeActiveStrokeColor: '000000',
        wayPointVisible: false,
        pinVisible: false,
      },
    );
    map.geoObjects.add(routeRef.current);
  }, [map, coordsWithCourier, ymapsRef]);

  const handleIncrement = useCallback(() => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom + 1, { duration: 200 });
    }
  }, [map]);

  const handleDecrement = useCallback(() => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom - 1, { duration: 200 });
    }
  }, [map]);

  const handleGetGeoocation = (coords: [number, number]) => {
    if (map) map.panTo(coords);
  };

  useEffect(() => {
    if (!routeRef.current || !ymapsRef || !map) return;

    routeRef.current.model.setReferencePoints(coordsWithCourier);
  }, [map, ymapsRef, coordsWithCourier]);

  useEffect(() => {
    if (!map || !loaded || initialized) return;

    handleInit();
    setInitialized(true);
  }, [map, loaded, initialized, handleInit]);

  return (
    <div className={cn(styles.wrapper, className)} {...restProps}>
      <Map
        className={styles.map}
        defaultState={{
          center: coordsWithCourier[0] || [55.755864, 37.617698],
          zoom: 12,
          behaviors: ['drag', 'dblClickZoom', 'multiTouch'],
        }}
        modules={['Placemark', 'multiRouter.MultiRoute']}
        instanceRef={(instance) => setMap(instance)}
        onLoad={(ym) => {
          ymapsRef.current = ym;
          setLoaded(true);
        }}
      >
        {points.map((point) => {
          if (point.type === 'warehouse')
            return <CustomPlacemark type='warehouse' point={points[0]} />;

          if (point.type === 'current_client')
            return (
              <CustomPlacemark
                type='client'
                point={points[points.length - 1]}
                timeFrom={order?.deliveryTimeFrom}
                timeTo={order?.deliveryTimeTo}
              />
            );

          if (point.type === 'client' && !point.completed)
            return <CustomPlacemark type='default' key={point.sequence} point={point} />;
        })}

        {courierCoords && <CustomPlacemark type='courier' coords={courierCoords} />}

        <Controls
          className={styles.controls}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          onGetGeolocation={handleGetGeoocation}
        />
      </Map>
    </div>
  );
};

const YandexMapProvider: FC<YandexMapProps> = (props) => {
  const { yandexApiKey } = useGlobalConfig();

  if (!props.route) return null;

  return (
    <YMaps query={{ lang: 'ru_RU', apikey: yandexApiKey }}>
      <YandexMap {...props} />
    </YMaps>
  );
};

export default YandexMapProvider;
