import { lazy, useEffect, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';

import * as HeaderStore from '@Stores/Header';
import * as PageLock from '@Stores/PageLock';
import Suspense from '@Components/Suspense';
import useMedias from '@Hooks/useMedias';
import useKeyboardEvents from '@Hooks/useKeyboardEvents';
import styles from './SideBar.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface SideBarProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const SideBarContent = lazy(() => import('./elems/SideBarContent'));

const SideBar: FC<SideBarProps> = (props) => {
  const { isOnlyMobile } = useMedias();
  const { isOpenedSidebar, sidebarAnimationTime } = HeaderStore.useMenu();

  const handleCloseSideBar = useCallback(() => {
    HeaderStore.closeSideBar();
    HeaderStore.closeSearch();
  }, []);

  // Блокируем скролл, когда сайдбар открыт
  useEffect(() => {
    if (isOpenedSidebar) {
      PageLock.add('menu');
    } else {
      PageLock.remove('menu');
    }
    return () => {
      PageLock.remove('menu');
    };
  }, [isOpenedSidebar]);

  // Принудительно закрываем сайдбар, если человек перешел на декстоп-версию, открыв сайдбар в мобильной
  // Иначе у него будет страница с заблокированным скролом
  useEffect(() => {
    if (isOnlyMobile) return;
    HeaderStore.closeSideBar();
  }, [isOnlyMobile]);

  // Закрываем сайдбар по нажатию на Escape
  useKeyboardEvents({
    onEscape: HeaderStore.closeSideBar,
  });

  return (
    <CSSTransition
      timeout={sidebarAnimationTime}
      classNames={{ enterActive: styles.enterActive, enterDone: styles.enterDone }}
      unmountOnExit
      in={isOpenedSidebar}
    >
      <div {...props} className={styles.container}>
        <div className={styles.content}>
          <CSSTransition timeout={sidebarAnimationTime} unmountOnExit in={isOpenedSidebar}>
            <Suspense fallback={null}>
              <SideBarContent />
            </Suspense>
          </CSSTransition>
        </div>

        <div className={styles.overlay} onClick={handleCloseSideBar} />
      </div>
    </CSSTransition>
  );
};

export default SideBar;
