import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import transformCart from './helpers/transformCart';
import useCartKeys from './useCartKeys';
import useRequest from '@Hooks/useRequest';

import type { CartData } from '@Types/Cart';

function useRemoveHidden() {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  return useMutation<CartData, Error>(
    async () => {
      const cart = await ApiCart.removeHidden();

      return transformCart({ cart });
    },
    {
      onSuccess: (cart) => {
        queryClient.setQueryData(keys, (prev: CartData) => ({ ...prev, ...cart }));
      },
    },
  );
}

export default useRemoveHidden;
