import { memo } from 'react';
import { Boldik, cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import useFeatureFlags from '@Hooks/useFeatureFlags';
import useTranslation from '@Queries/useTranslation';
import Link from '@Navigation/Link';
import LanguageSwitch from '@Components/LanguageSwitch';
import styles from './MenuRight.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface MenuRightProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: MainMenuItem[];
}

const MenuRight: FC<MenuRightProps> = (props) => {
  const { className, items = [], ...oldProps } = props;
  const { multipleLangAvailable } = useFeatureFlags();
  const { t } = useTranslation();

  const handleClickPhone = () => ModalWindows.open('Feedback');

  return (
    <div {...oldProps} className={cn(styles.mainWrapper, className)}>
      {multipleLangAvailable && <LanguageSwitch />}

      <div className={styles.sampleFabrics}>
        {items.length > 0 &&
          items.map((menuItem) => (
            <Link
              key={menuItem.url}
              className={styles.orderFabrics}
              to={menuItem.url}
              ssr={menuItem.render}
            >
              <Boldik className={styles.fabricsText}>{menuItem.name}</Boldik>
            </Link>
          ))}
      </div>
      <div onClick={handleClickPhone} className={styles.callback}>
        <Boldik className={styles.callbackText}>{t('ui.callback')}</Boldik>
        <IconChevronRight className={styles.callbackArrow} />
      </div>
    </div>
  );
};

export default memo(MenuRight);
