import { useMutation } from '@tanstack/react-query';

import useInfoStripe from '@Queries/InfoStripe/useInfoStripe';
import setCookie from '@Utils/setCookie';
import * as HeaderStore from '@Stores/Header';
import useDeps from '@Contexts/DI/useDeps';
import * as ApiMeta from '@Api/Meta';

export const closeTopline = (
  queryInfoStripe: ReturnType<typeof useInfoStripe>,
  analytics: ReturnType<typeof useDeps>['analytics'],
): void => {
  const infoStripe = queryInfoStripe?.data;

  if (!infoStripe) return;

  setCookie(infoStripe.cookie, '1', { 'max-age': infoStripe.cookieTime });
  HeaderStore.setSizes({ toplineHeight: 0 });

  queryInfoStripe.remove();

  analytics.dispatchEvent('topline.close', {
    text: infoStripe.text,
  });
};

const useCloseInfoStripe = () => {
  const { analytics, logger } = useDeps();
  const queryInfoStripe = useInfoStripe();

  const mutation = useMutation(
    () => {
      return ApiMeta.closeInfoStripe();
    },
    {
      onMutate: () => {
        closeTopline(queryInfoStripe, analytics);
      },
      onError: (e) => {
        logger.error(e);
      },
    },
  );

  return mutation;
};

export default useCloseInfoStripe;
