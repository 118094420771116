import { useQuery } from '@tanstack/react-query';

import useSearchParams from '@Hooks/useSearchParams';
import useDeps from '@Contexts/DI/useDeps';
import Api from '@Api/DeliveryTracking';

import type { UseQueryResult } from '@tanstack/react-query';
import type { CourierPositionDTO, CourierPositionParams } from '@Api/DeliveryTracking/typings';
import type { CourierPosition } from '@Types/DeliveryTracking';

const refetchInterval = 2000; // Заменить на 30000

const useCourierPosition = (params: CourierPositionParams): UseQueryResult<CourierPosition> => {
  const { logger } = useDeps();
  const id = useSearchParams().get('id');

  const keys = ['delivery-courier-position', id];

  const getCourierPosition = async () => {
    try {
      const courierPosition = await Api.getCourierPosition(params);

      return courierPosition;
    } catch (err) {
      logger.error(err);

      return null;
    }
  };

  const result = useQuery<CourierPositionDTO, Error, CourierPosition>(keys, getCourierPosition, {
    cacheTime: 0,
    refetchInterval,
    select(data) {
      if (!data || !data.lat || !data.lon) return {} as CourierPosition;

      return { coordinates: [+data.lat, +data.lon] };
    },
  });

  return result;
};

export default useCourierPosition;
