import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { AbTestOptions, AbTests } from '@Types/AbTests';

async function getAbTests(search = '', options: AbTestOptions = {}) {
  const searchParams = new URLSearchParams(search);
  const queryBody = {};

  searchParams.forEach((value, key) => {
    if (key.startsWith('utm_')) queryBody[key] = value;
  });

  const url = '/backend/data/abtests';
  const res = await Api.queryProxi<ApiResponse<AbTests>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...queryBody, ...options }),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getAbTests;
