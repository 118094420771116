import { memo, useCallback, lazy, useMemo } from 'react';
import { cn } from '@divlab/divanui';
import { IconBasket, IconFavorites, IconUser } from '@divlab/divanui/icons';

import * as Profile from '@Queries/Profile';
import * as Cart from '@Queries/Cart';
import * as Compare from '@Queries/Compare';
import * as Experiments from '@Queries/Experiments';
import * as ModalWindows from '@Stores/ModalWindows';
import Suspense from '@Components/Suspense';
import FeedbackPhone from '@Components/FeedbackPhone';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import useMedias from '@Hooks/useMedias';
import useCountries from '@Hooks/useCountries';
import useDeps from '@Contexts/DI/useDeps';
import Link from '@Navigation/Link';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import styles from './UserMenu.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface UserMenuProp extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const AuthPopup = lazy(() => import('@Layouts/elems/AuthPopup'));
const ProfilePopup = lazy(() => import('@Layouts/elems/ProfilePopup'));

const handleMouseEnterUser = () => Profile.updateWelcomePopup({ hovered: true });

const handleMouseLeaveUser = () => Profile.updateWelcomePopup({ hovered: false });

const handleClickOutside = () => {
  Profile.updateWelcomePopup({ clicked: false, hovered: false });
};

const UserMenu: FC<UserMenuProp> = (props) => {
  const { className, ...restProps } = props;
  const { isKaz } = useCountries();
  const { isOrderCheck } = useRouteMatchers();
  const cart = Cart.useCart();
  const profile = Profile.useProfile();
  const compareList = Compare.useCompareProductsCount();
  const featureFlags = Experiments.useFeatureFlags();
  const { isMobile, isMobileM, isDesktop } = useMedias();
  const { hovered, clicked } = Profile.useWelcomePopup();
  const visibleProfilePopup = (!isMobile && hovered) || clicked;
  const { analytics } = useDeps();

  const cartCount = useMemo(() => {
    return cart.data?.count || 0;
  }, [cart]);

  const compareCount = useMemo(() => {
    return compareList.data?.productCount || 0;
  }, [compareList.data]);

  const handleClickBasket = useCallback(() => {
    analytics.dispatchEvent('cart.clickIcon');
  }, [analytics]);

  const handleClickUser = useCallback(() => {
    Profile.updateWelcomePopup({ hovered: true, clicked: !clicked });
    if (isKaz && !profile.data) {
      //Для Казахстана сразу показываем модалку с авторизацией
      ModalWindows.open('Authorization');
    }
  }, [clicked, isKaz, profile.data]);

  const refPopup = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  return (
    <div {...restProps} className={cn(styles.list, className)}>
      {isDesktop && !isMobileM && <FeedbackPhone className={styles.phone} />}

      {!isMobileM && featureFlags.personalAreaAvailable && (
        <div
          aria-label='Личный кабинет'
          role='button'
          className={styles.userMenuItem}
          ref={refPopup}
          onMouseEnter={handleMouseEnterUser}
          onMouseLeave={handleMouseLeaveUser}
          onClick={handleClickUser}
        >
          <IconUser className={styles.icon} full={!!profile.data} />

          {visibleProfilePopup && (
            <Suspense fallback={null}>
              <div className={styles.popup}>
                {profile.data ? (
                  <ProfilePopup
                    profile={profile.data}
                    full={clicked}
                    onClickItem={handleClickOutside}
                  />
                ) : (
                  !isKaz && <AuthPopup />
                )}
              </div>
            </Suspense>
          )}
        </div>
      )}

      <Link
        className={styles.userMenuItem}
        to='/favorites'
        aria-label='Избранное'
        role='button'
        data-testid={isMobileM ? 'favorites-sum-mobile' : 'favorites-sum-desktop'}
      >
        <IconFavorites className={styles.icon} full={!!compareCount} />
        {!!compareCount && <span className={styles.count}>{compareCount}</span>}
      </Link>

      <Link
        to='/order/check'
        aria-label='Корзина'
        role='button'
        className={styles.userMenuItem}
        onClick={handleClickBasket}
        data-testid={isMobileM ? 'basket-mobile' : 'basket-desktop'}
      >
        <IconBasket className={styles.icon} full={!!cartCount} active={isOrderCheck} />
        {!!cartCount && <span className={styles.count}>{cartCount}</span>}
      </Link>
    </div>
  );
};

export default memo(UserMenu);
