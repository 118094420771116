import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ConstructorValueData } from '@Pages/PageProduct/pages/PageProductConstructor';
import type { ProductData } from '@Types/Product';

export interface ResultData {
  products: ProductData[];
}

export interface Result {
  ok: 'success' | 'error';
  data: ResultData;
}

async function addProduct(
  ids: number | number[],
  hash?: string,
  parameterValues?: ConstructorValueData[],
) {
  const idsArray = Array.isArray(ids) ? ids : [ids];
  const formatedData = idsArray.map((id) => ({ id, hash, parameterValues }));
  const url = '/backend/compare/v2';
  const res = await Api.queryProxi<Result>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formatedData),
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default addProduct;
