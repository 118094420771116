import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as ApiCompare from '@Api/Compare';

import type { ProductData } from '@Types/Product';

function useInCompare(id: ProductData['id'], hash?: string) {
  return useQuery(['compare'], ApiCompare.getCompareProducts, {
    notifyOnChangeProps: ['data'],
    placeholderData: { products: [] },
    select: useCallback(
      ({ products }) =>
        products.some((product) => {
          if (product?.hash) {
            return product.hash === hash && product.id === id;
          }
          return product.id === id;
        }),
      [hash, id],
    ),
  });
}

export default useInCompare;
