import { memo } from 'react';
import { Boldik, cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import Link from '@Navigation/Link';
import styles from './SecondLevelItem.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { FC, MouseEvent, HTMLAttributes } from 'react';

export interface MainNavProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  fixed?: boolean;
  menuItem: MainMenuItem;
  onClick?: (e: MouseEvent) => void;
}

const SecondLevelItem: FC<MainNavProps> = (props) => {
  const { menuItem, fixed, onClick, ...restProps } = props;
  const { secondLevel, isOpenNewTab } = HeaderStore.useSelectedLevels();

  return (
    <div
      {...restProps}
      className={cn(styles.item, {
        [styles.fixed]: fixed,
        [styles.promo]: menuItem.parameters?.promo,
      })}
    >
      <Link
        className={cn(
          styles.title,
          { [styles.active]: secondLevel?.id === menuItem.id },
          menuItem.url && 'MenuLink',
        )}
        to={menuItem.url}
        ssr={menuItem.render}
        onClick={onClick}
        target={isOpenNewTab && '_blank'}
      >
        <Boldik className={styles.name}>{menuItem.name}</Boldik>
      </Link>
    </div>
  );
};

export default memo(SecondLevelItem);
