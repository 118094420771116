import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

export interface Params {
  productId: number;
}

async function getInstallmentVariants(params: Params) {
  const { productId } = params;
  const searchParams = new URLSearchParams();

  searchParams.set('id', productId.toString());

  const url = `/backend/order/installment-available?${searchParams.toString()}`;
  const res = await Api.queryProxi<any>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getInstallmentVariants;
