import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';
import useRequest from '@Hooks/useRequest';

import type { CartData } from '@Types/Cart';

interface ApplyPromocodeBody {
  [key: string]: string | number;
}

export const useApplyPromocode = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  const { mutate, isLoading } = useMutation(
    async (coupon: ApplyPromocodeBody) => {
      const res = await ApiCart.sendCheckerPromocode(coupon);
      return res;
    },
    {
      onSuccess: (NewCart) => {
        queryClient.setQueriesData(keys, (prev: CartData) => {
          return {
            ...prev,
            ...NewCart,
          };
        });
      },
    },
  );

  return {
    applyPromocode: mutate,
    isLoading,
  };
};

export default useApplyPromocode;
