import { useMutation } from '@tanstack/react-query';

import transformCart from '@Queries/Cart/helpers/transformCart';
import useRequest from '@Hooks/useRequest';
import useCartKeys from './useCartKeys';

import type { BankOffer, CartData } from '@Types/Cart';

export type OfferData = Record<BankOffer, boolean>;

function useToggleBankOffer() {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  return useMutation(
    async (offer?: OfferData) => {
      const cart: CartData = queryClient.getQueryData(keys);
      return transformCart({ cart: { cart }, offer });
    },
    {
      onSuccess: (cart) => {
        queryClient.setQueryData(keys, (prev: CartData) => ({ ...prev, ...cart }));
      },
    },
  );
}

export default useToggleBankOffer;
