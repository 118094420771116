import { memo, useCallback, useMemo } from 'react';
import { cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import * as Profile from '@Queries/Profile';
import SecondLevelItem from '../SecondLevelItem';
import styles from './SecondLevelNav.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface SecondLevelNavProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  fixed?: boolean;
  onMouseEnterItem?: (e: MouseEvent, item: MainMenuItem) => void;
  onMouseLeaveItem?: (e: MouseEvent, item: MainMenuItem) => void;
  onClickItem?: (e: MouseEvent) => void;
}

const SecondLevelNav: FC<SecondLevelNavProps> = (props) => {
  const { className, fixed, onMouseLeaveItem, onMouseEnterItem, onClickItem, ...restProps } = props;
  const { firstLevel } = HeaderStore.useSelectedLevels();
  const profile = Profile.useProfile();
  const isDesigner = profile.data?.clientType === 'designer';

  const secondLevelItems = useMemo(() => {
    return isDesigner
      ? firstLevel.children.filter((child) => child.id !== '1740')
      : firstLevel.children;
  }, [firstLevel.children, isDesigner]);

  const handleMouseEnter = useCallback(
    (e: MouseEvent, item: MainMenuItem) => {
      if (onMouseEnterItem) onMouseEnterItem(e, item);
    },
    [onMouseEnterItem],
  );

  const handleMouseLeave = useCallback(
    (e: MouseEvent, item: MainMenuItem) => {
      if (onMouseLeaveItem) onMouseLeaveItem(e, item);
    },
    [onMouseLeaveItem],
  );

  return (
    <div {...restProps} className={cn(styles.nav, className)}>
      {secondLevelItems.map((menuItem) => (
        <SecondLevelItem
          key={menuItem.id}
          fixed={fixed}
          menuItem={menuItem}
          onMouseEnter={(e) => handleMouseEnter(e, menuItem)}
          onMouseLeave={(e) => handleMouseLeave(e, menuItem)}
          onClick={onClickItem}
        />
      ))}
    </div>
  );
};

export default memo(SecondLevelNav);
