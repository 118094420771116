import { memo, forwardRef, useRef, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './DropDownWrapper.module.css';

import type { HTMLAttributes, MouseEvent, ReactChild, CSSProperties } from 'react';

export interface DropDownWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactChild;
  opened?: boolean;
  containerHeight: number;
  onMouseLeave?: (e: MouseEvent) => void;
}

const DropDownWrapper = forwardRef<HTMLDivElement, DropDownWrapperProps>((props, ref) => {
  const { children, opened, containerHeight, onMouseLeave, ...restProps } = props;
  const [style, setStyle] = useState<CSSProperties>();
  const contentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!contentRef.current) return;

    const { clientHeight } = window.document.documentElement;
    const rect = contentRef.current.getBoundingClientRect();

    setStyle({
      height: `${clientHeight - rect.top}px`,
    });
  }, [opened]);

  return (
    <CSSTransition
      classNames={{
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      in={opened}
      timeout={200}
      unmountOnExit
    >
      <div className={styles.content} {...restProps} ref={contentRef} style={style}>
        <div className={styles.wrapper} ref={ref} onMouseLeave={onMouseLeave}>
          <div className={styles.inner}>
            <div className={styles.container} style={{ minHeight: containerHeight }}>
              <div className={styles.separator}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
});

DropDownWrapper.displayName = 'DropDownWrapper';

export default memo(DropDownWrapper);
