import type { Telephony } from '@Infrastructure/entities/telephony';
import type { Logger } from '@Infrastructure/entities/logger';
import type { CountryCode } from '@Types/Base';

interface Settings {
  calltouchUrl: string;
  calltouchClientUrl: string;
  modId: string;
  logger: Logger;
  country: CountryCode;
}

interface CalltouchResponseItem {
  phoneNumber: string;
  subPoolId: number;
  subPoolName: string;
}

class CalltouchService implements Telephony {
  private country: CountryCode;

  private status: 'unavailable' | 'loading' | 'loaded';

  private resolvers: ((value: string) => void)[];

  private resolveAll() {
    this.resolvers.forEach((resolve) => {
      resolve('');
    });

    this.resolvers = [];
    this.status = 'unavailable';
  }

  private loadInitScript(calltouchUrl: string, modId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `${calltouchUrl}?id=${modId}`;
      script.onload = () => resolve(true);
      script.onerror = (error) => reject(error);
      document.body.appendChild(script);
    });
  }

  private loadClientScript(calltouchClientUrl: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const clientSessionScript = document.querySelector<HTMLScriptElement>(
        `script[src^="${calltouchClientUrl}"]`,
      );

      if (clientSessionScript) {
        clientSessionScript.onload = () => resolve(true);
        clientSessionScript.onerror = (error) => reject(error);

        return;
      }

      reject('Calltouch client script not found');
    });
  }

  private loadCalltouchScript(settings: Settings): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { calltouchUrl, calltouchClientUrl, modId, logger } = settings;

      try {
        await this.loadInitScript(calltouchUrl, modId);
        await this.loadClientScript(calltouchClientUrl);

        resolve(true);
      } catch (error) {
        logger.log(error);

        reject();
      }
    });
  }

  constructor(settings: Settings) {
    const { calltouchUrl, calltouchClientUrl, modId, country } = settings;

    this.resolvers = [];
    this.status = 'unavailable';

    if (!calltouchUrl || !calltouchClientUrl || !modId) return;

    this.country = country;
    this.status = 'loading';

    window.CalltouchDataObject = 'ct';
    window.calltouchModId = modId;

    window.ct = function (...args: unknown[]) {
      window.ct.callbacks.push(args);
    };

    if (!window.ct.callbacks) {
      window.ct.callbacks = [];
    }

    window.ct.loaded = false;
    window.ct.counters = [modId];

    this.loadCalltouchScript(settings)
      .then(() => {
        this.status = 'loaded';

        const calltrackingParams = window.ct('calltracking_params', modId);

        if (!calltrackingParams) this.resolveAll();
      })
      .catch(() => {
        this.resolveAll();
      });
  }

  getPhone(region: string): Promise<string> {
    return new Promise((resolve) => {
      if (!window.ct || this.status === 'unavailable') return resolve('');

      if (this.status === 'loading') this.resolvers.push(resolve);

      window.ct(window.calltouchModId, 'dynamic_replacement', {
        callback: (success: boolean, res: CalltouchResponseItem[]) => {
          if (!success) return resolve('');

          resolve(res[0].phoneNumber);
        },
        subPoolNamesContains: [
          this.country === 'UZB' && new URL(document.URL).searchParams.get('utm_medium') === 'cpc'
            ? `${region}_cpc`
            : region,
        ],
      });
    });
  }
}

export default CalltouchService;
