import { memo, useCallback, useEffect, useMemo, useRef, useState, lazy } from 'react';
import { cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import * as Meta from '@Queries/Meta';
import * as ModalWindows from '@Stores/ModalWindows';
import Suspense from '@Components/Suspense';
import FeedbackPhone from '@Components/FeedbackPhone';
import Logotype from '@Components/Logotype';
import LogotypeDivanClub from '@Components/LogotypeDivanClub';
import Link from '@Navigation/Link';
import UILink from '@UI/Link';
import useRequest from '@Hooks/useRequest';
import usePromotionsForMenu from '@Queries/usePromotionsForMenu';
import { useLayout } from '@Queries/useLayout';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import useMedias from '@Hooks/useMedias';
import Search from '@Layouts/elems/Search';
import UserMenu from '../UserMenu';
import FirstLevelNav from '../FirstLevelNav';
import SecondLevelNav from '../SecondLevelNav';
import Back from '../Back';
import DropDown from '../DropDown';
import styles from './HeaderDesktop.module.css';

import type { FC, MouseEvent, HTMLAttributes, MutableRefObject, CSSProperties } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const RegionQuestionPopup = lazy(() => import('@Layouts/elems/RegionQuestionPopup'));

const HeaderDesktop: FC<HeaderProps> = () => {
  const { isOnlyDesktop } = useMedias();
  const { fixed } = HeaderStore.useMenu();
  const { firstLevel, secondLevel, initialFirstLevel } = HeaderStore.useSelectedLevels();
  const { toplineHeight } = HeaderStore.useSizes();
  const { isIndex, isDivanClub, isOrderCheck } = useRouteMatchers();
  const { country, language, region } = useRequest();
  const { data: promotions } = usePromotionsForMenu();
  const [hoverOnMenu, setHoverOnMenu] = useState(false);
  const [hiddenSecondLevel, setHiddenSecondLevel] = useState(fixed);
  const [opened, setOpened] = useState(false);
  const timeoutLvlChange = useRef<NodeJS.Timer>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const firstLevelRef = useRef<HTMLDivElement>(null);
  const secondLevelRef = useRef<HTMLDivElement>(null);
  const thirdLevelRef = useRef<HTMLDivElement>();
  const meta = Meta.useMeta();
  const layout = useLayout();
  const regionQuestion = Meta.useRegionQuestion();

  // Время на "прицеливание"
  // Человек может переходить от одного пункта меню к другому и случайно цеплять соседние пункты меню или выходить за его пределы,
  // из-за чего итоговое состояние будет совсем не то, которое хотел бы пользователь
  // Поэтому мы добавляем задержку, в течение которой подобные "ошибочные" события будут игнорироваться
  const timeToAim = useRef(300);

  const containerHeight = useMemo(() => {
    let maximumMenuItems = 0;

    //Average menu item height
    const minHeightMultiplier = 39;

    layout.data.header.forEach((firstLevelItem) => {
      firstLevelItem.children.forEach((secondLevelItem) => {
        secondLevelItem.children.forEach((thirdLevelItem) => {
          if (thirdLevelItem.children.length > maximumMenuItems) {
            maximumMenuItems = thirdLevelItem.children.length;
          }
        });
      });
    });

    return maximumMenuItems * minHeightMultiplier;
  }, [layout.data.header]);

  const needBack = useMemo(() => {
    return meta.data?.isTerminal && isOnlyDesktop && !isIndex;
  }, [isIndex, isOnlyDesktop, meta.data?.isTerminal]);

  const style = useMemo<CSSProperties>(() => {
    return {
      marginTop: fixed ? `-${toplineHeight}px` : null,
    };
  }, [fixed, toplineHeight]);

  const matchedPromotion = useMemo(() => {
    let firstLevelURL = firstLevel?.url || '';
    let secondLevelURL = secondLevel?.url || '';

    if (language.slug) {
      firstLevelURL = firstLevelURL.replace(`/${language.slug}`, '');
      secondLevelURL = secondLevelURL.replace(`/${language.slug}`, '');
    }

    if (region) {
      firstLevelURL = firstLevelURL.replace(`/${region}`, '');
      secondLevelURL = secondLevelURL.replace(`/${region}`, '');
    }

    const allPromotions = promotions?.products || [];

    return allPromotions.find((promotion) => {
      return (
        promotion.materials[0].path[0] === firstLevelURL &&
        promotion.materials[0].path[1] === secondLevelURL
      );
    });
  }, [firstLevel?.url, language.slug, promotions, region, secondLevel?.url]);

  const tryHideSecondLevel = useCallback(() => {
    setHiddenSecondLevel(() => {
      if (!fixed || opened) return false;
      if (hoverOnMenu) return false;

      return true;
    });
  }, [fixed, hoverOnMenu, opened]);

  const isMoveToLevel = useCallback((e, lvlRef: MutableRefObject<HTMLElement>) => {
    return (
      !e.relatedTarget ||
      !e.relatedTarget.nodeType ||
      e.relatedTarget === lvlRef.current ||
      lvlRef.current?.contains(e.relatedTarget)
    );
  }, []);

  const handleClickLocation = useCallback(() => {
    ModalWindows.open('RegionSelector');
  }, []);

  const resetSecondLevel = useCallback(() => {
    HeaderStore.setSelectedLevels({ secondLevel: null });
  }, []);

  const handleMouseEnterFirstLevel = useCallback(() => {
    setHoverOnMenu(true);
    setHiddenSecondLevel(false);
  }, []);

  const handleMouseLeaveFirstLevel = useCallback(() => {
    clearTimeout(timeoutLvlChange.current);
    timeoutLvlChange.current = setTimeout(() => {
      setHoverOnMenu(false);

      if (!initialFirstLevel) return;

      HeaderStore.setSelectedLevels({ firstLevel: initialFirstLevel });
    }, timeToAim.current);
  }, [initialFirstLevel]);

  const handleChangeItemFirstLevel = useCallback(
    (e: MouseEvent, item: MainMenuItem) => {
      handleMouseEnterFirstLevel();

      clearTimeout(timeoutLvlChange.current);
      timeoutLvlChange.current = setTimeout(() => {
        HeaderStore.setSelectedLevels({ firstLevel: item });
      }, timeToAim.current);
    },
    [handleMouseEnterFirstLevel],
  );

  const handleCloseMenu = useCallback(() => {
    setOpened(false);
    resetSecondLevel();

    HeaderStore.setSelectedLevels({ firstLevel: initialFirstLevel });
  }, [initialFirstLevel, resetSecondLevel]);

  const handleMouseEnterSecondLevelItem = useCallback(
    (e: MouseEvent, item: MainMenuItem) => {
      clearTimeout(timeoutLvlChange.current);

      HeaderStore.setSelectedLevels({ secondLevel: item });
      if (!isOrderCheck) setOpened(true);
    },
    [isOrderCheck],
  );

  const handleMouseLeaveSecondLevel = useCallback(() => {
    setHoverOnMenu(false);
    tryHideSecondLevel();

    resetSecondLevel();
    setOpened(false);

    clearTimeout(timeoutLvlChange.current);
    timeoutLvlChange.current = setTimeout(() => {
      HeaderStore.setSelectedLevels({ firstLevel: initialFirstLevel });
    }, timeToAim.current);
  }, [tryHideSecondLevel, resetSecondLevel, initialFirstLevel]);

  const handleMouseLeaveDropDown = useCallback(
    (e: MouseEvent) => {
      // Если курсор остался на втором уровне, то ничего делать не нужно
      if (isMoveToLevel(e, secondLevelRef)) return;

      setOpened(false);
      resetSecondLevel();

      HeaderStore.setSelectedLevels({ firstLevel: initialFirstLevel });
    },
    [isMoveToLevel, resetSecondLevel, initialFirstLevel],
  );

  const handleClickLink = useCallback(() => {
    setOpened(false);
    resetSecondLevel();

    HeaderStore.setSelectedLevels({ firstLevel, initialFirstLevel: firstLevel });
  }, [firstLevel, resetSecondLevel]);

  // Скрываем/показываем второй уровень когда закрепляем/открепляем шапку
  useEffect(() => {
    tryHideSecondLevel();
  }, [fixed, tryHideSecondLevel]);

  // Рассчитываем высоту шапки
  useEffect(() => {
    if (!headerRef.current) return;

    const rectHeader = headerRef.current.getBoundingClientRect();

    function calculate() {
      HeaderStore.setSizes({ contentHeight: fixed ? 75 : rectHeader.height });
    }

    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, [fixed]);

  // Закрываем меню, когда курсор выходит за пределы окна браузера
  useEffect(() => {
    document.addEventListener('mouseleave', handleCloseMenu);

    return () => {
      document.removeEventListener('mouseleave', handleCloseMenu);
    };
  }, [handleCloseMenu]);

  return (
    <div
      className={cn(styles.header, {
        [styles.fixed]: fixed,
        [styles.hiddenSecondLevel]: hiddenSecondLevel,
      })}
      ref={headerRef}
      style={style}
    >
      <div className={cn(styles.firstLevel, styles.container)}>
        <div className={styles.headerIn}>
          <div className={styles.maxWidth}>
            <div className={styles.wrapperLogotype}>
              {needBack && <Back className={styles.back} />}
              <Link className={styles.slider} to='/' data-testid='logo-link'>
                {isDivanClub ? (
                  <LogotypeDivanClub className={styles.logotypeDivanClub} />
                ) : (
                  <Logotype country={country} />
                )}
              </Link>
            </div>

            <FirstLevelNav
              ref={firstLevelRef}
              fixed={fixed}
              onMouseEnter={handleMouseEnterFirstLevel}
              onMouseLeave={handleMouseLeaveFirstLevel}
              onChangeItem={handleChangeItemFirstLevel}
            />

            <div className={styles.search}>
              <Search />
            </div>
          </div>

          <div className={styles.right}>
            <UILink
              className={styles.location}
              underlined
              view='secondary'
              data-testid='region-name-link'
              onClick={handleClickLocation}
            >
              {meta.data.region.name}
            </UILink>

            {regionQuestion.data && (
              <Suspense fallback={null}>
                <RegionQuestionPopup data={regionQuestion.data} />
              </Suspense>
            )}

            <FeedbackPhone />
            <UserMenu className={styles.userMenu} />
          </div>
        </div>
      </div>

      <div className={styles.secondLevel} onMouseLeave={handleMouseLeaveSecondLevel}>
        <div className={cn(styles.secondLevelContainer, styles.container)} ref={secondLevelRef}>
          <SecondLevelNav
            fixed={fixed}
            onMouseEnterItem={handleMouseEnterSecondLevelItem}
            onClickItem={handleClickLink}
          />
        </div>

        <DropDown
          ref={thirdLevelRef}
          secondLevel={secondLevel}
          opened={opened}
          promotion={matchedPromotion}
          onMouseLeave={handleMouseLeaveDropDown}
          onClickLink={handleClickLink}
          containerHeight={containerHeight}
        />
      </div>
    </div>
  );
};

export default memo(HeaderDesktop);
