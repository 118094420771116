import { useQuery } from '@tanstack/react-query';

import useDeps from '@Contexts/DI/useDeps';

export const useDesignerRegions = () => {
  const { cabinetApi } = useDeps();
  const keys = ['designerRegions'];

  const query = useQuery(keys, cabinetApi.fetchDesignerRegions);

  return query;
};
