import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { cn } from '@divlab/divanui';
import { IconFavorites, IconPhone, IconMap, IconSearch } from '@divlab/divanui/icons';

import * as Compare from '@Queries/Compare';
import * as ModalWindows from '@Stores/ModalWindows';
import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import useDeps from '@Contexts/DI/useDeps';
import useCountryBasedData from '@Hooks/useCountryBasedData';
import Logotype from '@Components/Logotype';
import LogotypeDivanClub from '@Components/LogotypeDivanClub';
import Link from '@Navigation/Link';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import useRequest from '@Hooks/useRequest';
import styles from './HeaderMobileXS.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CountryBasedData } from '@Types/Base';

export interface HeaderMobileXSProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  withShadow?: boolean;
}

const siteNameData: CountryBasedData<string> = {
  RUS: 'divan.ru',
  BLR: 'divan.by',
  KAZ: 'mebel.kz',
  UZB: 'divan.uz',
};

const HeaderMobileXS: FC<HeaderMobileXSProps> = (props) => {
  const { className, withShadow, ...restProps } = props;
  const { logger, analytics } = useDeps();
  const { isDivanClub } = useRouteMatchers();
  const headerRef = useRef<HTMLDivElement>(null);
  const siteName = useCountryBasedData(siteNameData);
  const { country } = useRequest();
  const search = SearchStore.useSearch();
  const defaultValue = search.result.request;
  const compareList = Compare.useCompareList();

  const compareCount = useMemo(() => {
    return compareList.data?.count || 0;
  }, [compareList.data]);

  const handleClick = useCallback(async () => {
    try {
      await SearchStore.search({ term: defaultValue || '' });

      HeaderStore.openSearch();
    } catch (err) {
      logger.log(err);
      HeaderStore.closeSearch();
    }
  }, [defaultValue, logger]);

  const handleClickRegion = useCallback(() => {
    analytics.dispatchEvent('region.selector.open', { label: 'header' });
    ModalWindows.open('RegionSelector');
  }, [analytics]);

  const handleClickPhone = useCallback(() => {
    ModalWindows.open('Feedback');
  }, []);

  // Рассчитываем высоту шапки
  useEffect(() => {
    function calculate() {
      setTimeout(() => {
        if (!headerRef.current) return;
        const rectHeader = headerRef.current.getBoundingClientRect();
        HeaderStore.setSizes({ contentHeight: rectHeader.height });
      });
    }

    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, []);

  return (
    <div
      {...restProps}
      ref={headerRef}
      className={cn(
        styles.header,
        {
          [styles.withShadow]: withShadow,
        },
        className,
      )}
    >
      <div className={styles.left}>
        <div className={cn(styles.userMenuItem, styles.mapWrapper)} onClick={handleClickRegion}>
          <IconMap className={styles.map} />
        </div>
        <div
          className={cn(styles.userMenuItem, styles.search)}
          onClick={handleClick}
          data-testid='search'
        >
          <IconSearch />
        </div>
      </div>

      <Link to='/' aria-label={siteName}>
        {isDivanClub ? <LogotypeDivanClub /> : <Logotype country={country} />}
      </Link>

      <div className={styles.right}>
        <Link className={styles.userMenuItem} to='/favorites' data-testid='favorites-sum-mobile'>
          <IconFavorites className={styles.icon} full={!!compareCount} />
          {!!compareCount && <span className={styles.count}>{compareCount}</span>}
        </Link>
        <div
          className={styles.userMenuItem}
          onClick={handleClickPhone}
          data-testid='callback-mobile'
        >
          <IconPhone className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderMobileXS);
