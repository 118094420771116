import { memo } from 'react';
import { cn, Boldik } from '@divlab/divanui';

import Link from '@Navigation/Link';
import styles from './MenuLeft.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface MenuLeftProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: MainMenuItem[];
}

const MenuLeft: FC<MenuLeftProps> = (props) => {
  const { className, items, ...oldProps } = props;

  return (
    <div {...oldProps} className={cn(styles.mainWrapper, className)}>
      {items.map((menuItem, index) => (
        <Link
          key={index}
          className={cn(styles.menuItem, menuItem.url && 'MenuLink')}
          to={menuItem.url}
          ssr={menuItem.render}
        >
          <Boldik className={styles.menuName}>{menuItem.name}</Boldik>
        </Link>
      ))}
    </div>
  );
};

export default memo(MenuLeft);
