import { useEffect, useRef, useState } from 'react';
import { cn } from '@divlab/divanui';

import Page from '@Components/Page';
import Title from '../../elems/Title';
import Map from '../../elems/Map';
import Cancellation from '../../elems/Cancellation';
import Completion from '../../elems/Completion';
import MapPlaceholder from '../../elems/MapPlaceholder';
import Footer from '../../elems/Footer';
import Header from '../../elems/Header';
import Info from '../../elems/Info';
import BottomSheet from '../../elems/BottomSheet';
import styles from './DeliveryPageMobile.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { DeliveryStatus, OrderState, RouteState } from '@Types/DeliveryTracking';

interface DeliveryPageProps extends HTMLAttributes<HTMLDivElement> {
  order: OrderState;
  route: RouteState;
  isDeliveryToday: boolean;
  status: DeliveryStatus;
}

const gap = 10;

const DeliveryPageMobile: FC<DeliveryPageProps> = (props) => {
  const { className, order, route, isDeliveryToday, status, ...restProps } = props;
  const [maxHeightBottomSheet, setMaxHeightBottomSheet] = useState(0);
  const [needsMap, setNeedsMap] = useState(null);
  const fixationRef = useRef<HTMLDivElement>(null);
  const mainContent = status === 'pending' || status === 'delivering';
  const canceledContent = status === 'canceled';
  const completedContent = status === 'completed';

  useEffect(() => {
    if (route?.length && (status === 'delivering' || (status === 'pending' && isDeliveryToday))) {
      setNeedsMap(true);
    } else {
      setNeedsMap(false);
    }
  }, [isDeliveryToday, route?.length, status]);

  useEffect(() => {
    if (needsMap) {
      document.body.style.overflow = 'hidden';
    }
  }, [needsMap]);

  useEffect(() => {
    function updateMaxHeightBottomSheet() {
      if (fixationRef.current && window) {
        setMaxHeightBottomSheet(innerHeight - fixationRef.current.offsetHeight - gap);
      }
    }

    updateMaxHeightBottomSheet();

    window.addEventListener('resize', updateMaxHeightBottomSheet);

    return () => window.removeEventListener('resize', updateMaxHeightBottomSheet);
  }, [status]);

  return (
    <Page>
      <div
        className={cn(styles.page, className, { [styles.withBottomSheet]: needsMap })}
        {...restProps}
      >
        <div ref={fixationRef}>
          <Header />
          <Title status={status} />
        </div>

        <div className={styles.container}>
          {mainContent && typeof needsMap === 'boolean' && (
            <>
              {needsMap ? (
                <>
                  <Map className={styles.map} order={order} route={route} />

                  <BottomSheet
                    className={styles.bottomSheet}
                    minHeight={143}
                    maxHeight={maxHeightBottomSheet}
                  >
                    <Info className={styles.info} status={status} order={order} route={route} />
                    <Footer className={styles.footer} />
                  </BottomSheet>
                </>
              ) : (
                <>
                  <MapPlaceholder className={styles.mapPlaceholder} order={order} />
                  <Info className={styles.info} status={status} order={order} route={route} />
                  <Footer className={styles.footer} />
                </>
              )}
            </>
          )}

          {canceledContent && (
            <>
              <Cancellation className={styles.cancellation} order={order} />
              <Footer className={styles.footer} />
            </>
          )}
          {completedContent && (
            <>
              <Completion order={order} />
              <Footer className={styles.footer} />
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default DeliveryPageMobile;
