import { useCallback, useMemo } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import useDeps from '@Contexts/DI/useDeps';
import styles from './CategoryList.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface CategoryListProps extends HTMLAttributes<HTMLDivElement> {
  menuItem: MainMenuItem;
  className?: string;
  view?: 'columns' | 'default';
  onClickLink?: (e: MouseEvent) => void;
}

const CategoryList: FC<CategoryListProps> = (props) => {
  const { menuItem, view = 'default', className, onClickLink } = props;
  const { analytics } = useDeps();

  const baseLinks = useMemo(() => {
    return menuItem.children.filter((item) => item.theme !== 'aggregate');
  }, [menuItem.children]);

  const aggregateLink = useMemo(() => {
    return menuItem.children.find((item) => item.theme === 'aggregate');
  }, [menuItem.children]);

  const handleClickLink = useCallback(
    (e: MouseEvent, item: MainMenuItem) => {
      if (!item) return;

      analytics.dispatchEvent('menu.item.click', {
        item,
      });

      if (onClickLink) onClickLink(e);
    },
    [onClickLink, analytics],
  );

  return (
    <>
      <div className={cn(styles.list, { [styles.viewColumns]: view === 'columns' }, className)}>
        {baseLinks.map((item) => (
          <div
            key={`${item.name}-${item.id}`}
            className={cn(styles.item, { [styles.themeAlternate]: item.theme === 'alternate' })}
          >
            <div className={styles.linkWrapper}>
              {item.subtitle && <div className={styles.subtitle}>{item.subtitle}</div>}
              <Link
                className={cn(styles.link, {
                  [styles.themeAlternate]: item.theme === 'alternate',
                })}
                to={item.url}
                onClick={(e: MouseEvent) => handleClickLink(e, item)}
              >
                {item.name}
              </Link>
            </div>
          </div>
        ))}
      </div>

      {aggregateLink && (
        <div className={styles.aggregateLink}>
          <Link
            to={aggregateLink.url}
            underlined
            view='secondary'
            onClick={(e: MouseEvent) => handleClickLink(e, aggregateLink)}
          >
            {aggregateLink.name}
          </Link>
        </div>
      )}
    </>
  );
};

export default CategoryList;
