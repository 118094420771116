import * as ApiPromo from '@Api/Promo';
import * as ApiMeta from '@Api/Meta';
import * as ApiCategory from '@Api/Category';
import * as Api from '@Api/index';
import * as ApiWiki from '@Api/Wiki';
import * as ApiCompare from '@Api/Compare';
import * as ApiOrder from '@Api/Order';
import ApiSite from '@Api/Site';
import CabinetApi from '@Api/Cabinet';
import DeliveryTrackingApi from '@Api/DeliveryTracking';
import cutOutRegionSlug from '@Utils/cutOutRegionSlug';
import { BackendUnavailableError } from '@Common/errors';
import generateQueryKeys from '../Queries/helpers/generateQueryKeys';
import transformUrl from './transformUrl';

import type { QueryClient } from '@tanstack/react-query';
import type { CountryCode, PageMeta } from '@Types/Base';
import type { ProfileData } from '@Types/Profile';
import type { ModalId } from '@Stores/ModalWindows/typings';

export interface TranslationParams {
  country: CountryCode;
  lang: string;
  pathname: string;
}

interface PrefetchCategoryParams {
  pathname: string;
  search: string;
  slug: string;
  pageNumber: number;
}

interface InfiniteWikiArticles {
  categoryId?: string | number;
  tagId?: string | number;
  pageNumber?: number;
}

interface PrefetchOrdersParams {
  page: number;
  search: string;
  profile: ProfileData;
}

interface PrefetcherParams {
  request: Request;
  queryClient: QueryClient;
  country: string;
  region: string;
}

type Prefetcher = (params: PrefetcherParams) => void;

// Данные для страницы
export const prefetchPage = async (
  url: string,
  queryClient: QueryClient,
  queryKeys: string[] = [],
) => {
  const { pathname, search } = new URL(url);
  const { headers, proxyOrigin, region, targetRegion, language } = Api.getRequest();
  const resultUrl = `${pathname}${search}`;
  const keys = generateQueryKeys({
    keys: ['page', targetRegion || region, ...queryKeys],
    pathname,
    search,
  });
  const queryUrl = `${proxyOrigin}/backend${resultUrl}`;
  const cache = queryClient.getQueryData(keys);
  const isBrowser = typeof document !== 'undefined';

  // Если данные уже есть в кэше, то ничего дополнительно делать не нужно
  if (cache) {
    return Promise.resolve();
  }

  let redirectResponse: Response;

  await queryClient
    .fetchQuery(keys, async () => {
      let res: Response;
      if (isBrowser) {
        res = await fetch(queryUrl, {
          headers: { ...headers, 'X-Request-Type': 'page' },
          credentials: 'include',
        });
      } else {
        res = await fetch(queryUrl, {
          headers: { ...headers, 'X-Request-Type': 'page' },
          redirect: 'manual',
        });
      }

      // Сервер может отправить нас на другую страницу
      const location = res.headers.get('Location');

      if (res.redirected || location) {
        const redirectUrl = location ? new URL(location, proxyOrigin) : new URL(res.url);
        const to = `${redirectUrl.pathname.slice('/backend'.length)}${redirectUrl.search}`;

        redirectResponse = new Response('', {
          // Статус ответа в браузере может быть 200 и редиректа не произойдет
          status: res.status > 300 ? res.status : 302,
          headers: {
            Location: to,
          },
        });
        //Пробрасываем ошибку чтобы не наполнять кэш и выйти из выполнения запроса
        throw new Error('redirect');
      }

      // Отдельный тип ошибки для случая, когда бэкенд недоступен
      if (res.status >= 500) {
        throw new BackendUnavailableError();
      }

      // В случае успешного ответа заполняем кэш react-query уже полученными данными
      // Для 404-й страницы также возвращаются данные о странице
      if ([200, 404].includes(res.status)) {
        const data = await res.json();

        // Подменяем региональный слаг в canonical ссылке
        const pageMeta: PageMeta = data.body?.data.meta;
        const canonicalTag = pageMeta?.tags.find(
          (tag) => tag.tag === 'link' && tag.props?.rel === 'canonical',
        );

        if (canonicalTag) {
          const canonicalUrl = new URL(canonicalTag.props.href);
          const newPathname = transformUrl({
            url: canonicalUrl.pathname,
            language: language.slug,
            targetRegion: targetRegion || region,
            isCanonical: true,
          });

          canonicalTag.props.href = `${canonicalUrl.origin}${newPathname}`;
        }

        return { ...data.body?.data, page: data.page };
      }
    })
    .catch((err: Error) => {
      if (err.name === 'BackendUnavailableError') {
        throw new BackendUnavailableError();
      }
    });

  if (redirectResponse) return redirectResponse;
};

// Данные для локализации страницы
export const prefetchTranslation = (url: string, queryClient: QueryClient) => {
  const { country, language } = Api.getRequest();
  const { pathname } = new URL(url);

  const translationParams: TranslationParams = {
    country,
    lang: language.id,
    pathname,
  };

  const keys = ['translation', 'page', translationParams];

  return queryClient.prefetchQuery(keys, () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiMeta.getPageTranslation(translationParams);
  });
};

// Информация о промо-категориях товаров
export const prefetchInfinitePromoCategory = (
  params: PrefetchCategoryParams,
  queryClient: QueryClient,
) => {
  const { pathname, search, slug, pageNumber } = params;
  const { region, targetRegion } = Api.getRequest();
  const keys = generateQueryKeys({
    keys: ['infinitePromoCategory', targetRegion || region, slug],
    pathname,
    search,
  });
  const cachedValue = queryClient.getQueryData(keys);

  if (cachedValue) return Promise.resolve(cachedValue);

  return queryClient.prefetchInfiniteQuery(keys, ({ pageParam }) => {
    return ApiCategory.getPromoProducts({
      slug,
      page: pageParam || pageNumber,
      search,
      isInit: true,
    });
  });
};

// Поиск
export const prefetchInfiniteSearch = (url: string, queryClient: QueryClient) => {
  const { pathname, search, searchParams } = new URL(url);
  const term = searchParams.get('ProductSearch[name]');
  const { region, targetRegion } = Api.getRequest();
  const keys = generateQueryKeys({
    keys: ['infiniteSearch', targetRegion || region],
    pathname,
    search,
  });
  const cachedValue = queryClient.getQueryData(keys);

  if (cachedValue) return Promise.resolve(cachedValue);

  return queryClient.prefetchInfiniteQuery(keys, ({ pageParam = 1 }) => {
    return ApiSite.searchProducts({
      term,
      page: pageParam,
      search,
    });
  });
};

// Список статей в Идеях и трендах
export const prefetchInfiniteWikiArticles = (
  params: InfiniteWikiArticles,
  queryClient: QueryClient,
) => {
  const { categoryId, tagId, pageNumber } = params;
  const keys = ['infiniteWikiArticles', categoryId, tagId].filter(Boolean);
  const cachedValue = queryClient.getQueryData(keys);

  if (cachedValue) return Promise.resolve(cachedValue);

  return queryClient.prefetchInfiniteQuery(keys, ({ pageParam = 1 }) => {
    return ApiWiki.getWikiArticles({ page: pageParam || pageNumber, categoryId, tagId });
  });
};

// Данные о списке сравнения товаров
export const prefetchCompare = (queryClient: QueryClient) => {
  const keys = ['compare'];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiCompare.getCompareProducts();
  });
};

// Профиль пользователя
export const prefetchProfile = (queryClient: QueryClient) => {
  const { country } = Api.getRequest();
  const keys = ['profile'];
  const cabinetApi = new CabinetApi({ country });

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return cabinetApi.fetchProfile();
  });
};

// Заказы пользователя
export const prefetchOrders = (params: PrefetchOrdersParams, queryClient: QueryClient) => {
  const { profile, page, search } = params;
  const keys = ['orders', page, search, { mindboxId: profile?.mindboxId }];
  const { country } = Api.getRequest();
  const cabinetApi = new CabinetApi({ country });

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return cabinetApi.fetchOrders({ page, search });
  });
};

// Рекламации пользователя
export const prefetchComplaints = (profile: ProfileData, queryClient: QueryClient) => {
  const { country } = Api.getRequest();
  const cabinetApi = new CabinetApi({ country });
  const keys = ['complaints', { mindboxId: profile?.mindboxId }];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return cabinetApi.fetchComplaints();
  });
};

// История начисления бонусов пользователя
export const prefetchBonusHistory = (profile: ProfileData, queryClient: QueryClient) => {
  const { country } = Api.getRequest();
  const cabinetApi = new CabinetApi({ country });
  const keys = ['bonus-history', { mindboxId: profile?.mindboxId }];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return cabinetApi.fetchBonusHistory();
  });
};

// Мета данные
export const prefetchMetaData = (queryClient: QueryClient) => {
  const { region, targetRegion, secondaryRegion, targetSecondaryRegion, language } =
    Api.getRequest();
  const keys = [
    'meta',
    {
      region: targetRegion || region,
      secondaryRegion: targetSecondaryRegion || secondaryRegion,
      lang: language.id,
    },
  ];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiMeta.fetch();
  });
};

// Данные устройства
export const prefetchDeviceData = (queryClient: QueryClient) => {
  const keys = ['device'];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiSite.detectDevice();
  });
};

// Данные Layout
export const prefetchLayoutData = (queryClient: QueryClient) => {
  const { region, targetRegion, language } = Api.getRequest();
  const keys = ['layout', { region: targetRegion || region, lang: language.id }];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiMeta.getLayout();
  });
};

// Данные для переводов
export const prefetchUITranslation = (queryClient: QueryClient) => {
  const { language, country } = Api.getRequest();
  const params = { lang: language.id, country };

  const keys = ['translation', 'ui', params];

  return queryClient.prefetchQuery(keys, () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiMeta.getUITranslation(params);
  });
};

// Информация о промоматериалах
export const prefetchPromotions = (
  params: { listName: string; type: string },
  queryClient: QueryClient,
) => {
  const { type, listName } = params;
  const { country, language, region, targetRegion } = Api.getRequest();

  const keys = [
    'promotions',
    { type, country, lang: language.id, region: targetRegion || region, listName },
  ];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiPromo.getList({
      type,
      country,
      lang: language.id,
      region: targetRegion || region,
      listName,
    });
  });
};

// Информация о категорийной акции
export const prefetchCategoryPromotion = (params: { slug: string }, queryClient: QueryClient) => {
  const { slug } = params;
  const { country, language, region, targetRegion } = Api.getRequest();
  const keys = [
    'category-promotion',
    { slug, country, lang: language.id, region: targetRegion || region },
  ];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiPromo.getByCategory({
      slug,
      country,
      lang: language.id,
      region: targetRegion || region,
    });
  });
};

export const prefetchInfoStripe: Prefetcher = (params) => {
  const { request, queryClient } = params;
  const { pathname } = new URL(request.url);
  const { region, targetRegion } = Api.getRequest();
  const keys = ['info-stripe', targetRegion || region, { pathname: cutOutRegionSlug(pathname) }];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiMeta.getInfoStripe(pathname);
  });
};

export const prefetchAllRegions = (queryClient: QueryClient) => {
  const { country, region, targetRegion, mainRegion } = Api.getRequest();
  const keys = ['allRegions'];

  // Only for main region in Russia
  if (country === 'RUS' && (targetRegion || region) === mainRegion) {
    return queryClient.prefetchQuery(keys, async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getAllRegions();
    });
  }

  return Promise.resolve();
};

export const prefetchSEOCategories = (queryClient: QueryClient) => {
  const { country, region, targetRegion, mainRegion } = Api.getRequest();
  const keys = ['seo-categories'];

  // Only for main region in Russia
  if (country === 'RUS' && (targetRegion || region) === mainRegion) {
    return queryClient.prefetchQuery(keys, async () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiSite.getSEOCategories();
    });
  }

  return Promise.resolve();
};

export const prefetchModalWindowTranslation = (modalId: ModalId, queryClient: QueryClient) => {
  const { country, language } = Api.getRequest();
  const queryParams = { lang: language.id, country, modalId };
  const keys = ['translation', 'modal-window', queryParams];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiMeta.getModalWindowTranslation(queryParams);
  });
};

export const prefetchOrderSections = (queryClient: QueryClient) => {
  const { country, language, region } = Api.getRequest();
  const queryParams = { lang: language.id, country, region };
  const keys = ['order-sections', queryParams];

  return queryClient.prefetchQuery(keys, async () => {
    const cache = queryClient.getQueryData(keys);

    if (cache) return Promise.resolve(cache);

    return ApiOrder.getSections();
  });
};

export const prefetchDeliveryTracking = (url: string, queryClient: QueryClient) => {
  const id = new URL(url).searchParams.get('id');

  const keys = ['delivery-order-state', id];

  const getOrderState = async () => {
    return DeliveryTrackingApi.getOrderState({ id });
  };

  return queryClient.prefetchQuery(keys, getOrderState);
};
