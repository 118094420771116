import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Languages from '@Components/Languages';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import styles from './LanguagesPopup.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface LanguagesPopupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onClose?: () => void;
}

const LanguagesPopup: FC<LanguagesPopupProps> = (props) => {
  const { className, onClose } = props;
  const refPopup = useOnClickOutside<HTMLDivElement>(onClose);

  return (
    <div className={cn(styles.popup, className)} ref={refPopup}>
      <Languages onClose={onClose} />
    </div>
  );
};

export default memo(LanguagesPopup);
