import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import useDevice from '@Queries/useDevice';
import useRequest from '@Hooks/useRequest';
import { isBrowser } from '@Utils/checkRuntime';

import type { RegionQuestionData } from '@Types/Region';

function useRegionQuestion() {
  const device = useDevice();
  const { queryClient } = useRequest();
  const keys = ['region-question'];

  const result = useQuery(
    keys,
    () => {
      if (device.data?.bot) return Promise.resolve(null);

      const cache = queryClient.getQueryData<RegionQuestionData>(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getRegionQuestion();
    },
    { enabled: isBrowser },
  );

  return result;
}

export default useRegionQuestion;
