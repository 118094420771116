import { useMutation } from '@tanstack/react-query';

import useDeps from '@Contexts/DI/useDeps';
import useRequest from '@Hooks/useRequest';
import useNavigation from '@Navigation/useNavigation';
import findPathPattern from '@Navigation/findPathPattern';

import type { UseMutateFunction } from '@tanstack/react-query';

export function useLogout(): [UseMutateFunction] {
  const { queryClient, region } = useRequest();
  const { cabinetApi } = useDeps();
  const navigation = useNavigation();

  const { mutate } = useMutation({
    mutationFn: () => {
      return cabinetApi.logout();
    },
    onSuccess: async () => {
      const pattern = findPathPattern(navigation.pathname);
      const isPrivatePage = pattern.includes('/cabinet');

      // Если в момент выхода пользователь находится на странице личного кабинета, то его нужно отправить на главную
      // В других случаях пользователь остаётся на текущей странице
      if (isPrivatePage) {
        await navigation.openPage({ url: `/${region}` });
      }

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
      }, 300);
    },
  });

  return [mutate];
}
