import { forwardRef, memo, useEffect } from 'react';
import { cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import * as PageLock from '@Stores/PageLock';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import DropDownWrapper from '../DropDownWrapper';
import CategoryList from '../../../../../elems/CategoryList';
import Banner from '../Banner';
import styles from './DropDown.module.css';

import type { HTMLAttributes, MouseEvent } from 'react';
import type { MainMenuItem } from '@Types/Layout';
import type { ProductsBannerData, Promotion } from '@Promo/typings';

export interface DropDownProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  opened?: boolean;
  promotion?: Promotion<ProductsBannerData>;
  secondLevel: MainMenuItem;
  containerHeight: number;
  onClickLink?: (e: MouseEvent) => void;
}

const DropDown = forwardRef<HTMLDivElement, DropDownProps>((props, ref) => {
  const { opened, secondLevel, promotion, onClickLink, ...restProps } = props;

  useEffect(() => {
    if (opened) {
      PageLock.add('menu');
    } else {
      PageLock.remove('menu');
    }
    return () => {
      PageLock.remove('menu');
    };
  }, [opened]);

  return (
    <DropDownWrapper {...restProps} opened={opened} ref={ref}>
      {secondLevel && (
        <div className={styles.flex}>
          <div
            className={cn(styles.wrapperList, {
              [styles.single]: secondLevel.theme === '1cl2im',
              [styles.double]: secondLevel.theme === '2cl2im',
            })}
          >
            {secondLevel.children.map((child) => (
              <div key={child.id} className={styles.wrapper}>
                {child.name &&
                  (child.root?.url ? (
                    <Link className={styles.subtitle} to={child.root.url} onClick={onClickLink}>
                      {child.name}
                      <IconChevronRight className={styles.subtitleIcon} />
                    </Link>
                  ) : (
                    <div className={styles.subtitle}>{child.name}</div>
                  ))}

                {child.subtitle && <div className={styles.description}>{child.subtitle}</div>}

                <div className={styles.categoryList}>
                  <CategoryList
                    menuItem={child}
                    view={secondLevel.theme === '2cl2im' ? 'columns' : 'default'}
                    onClickLink={onClickLink}
                  />
                </div>
              </div>
            ))}
          </div>

          {secondLevel.theme === '1cl2im' || secondLevel.theme === '2cl2im' ? (
            <div className={styles.gallery}>
              {secondLevel.gallery?.map((item) => (
                <Link to={item.url} className={styles.galleryItem} key={item.image.src}>
                  <Img src={item.image.src} className={styles.galleryImage} />
                  <div className={styles.gallerySign}>
                    <div className={styles.gallerySubtitle}>{item.subtitle}</div>
                    <div className={styles.galleryName}>{item.name}</div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className={styles.wrapperBanner}>
              {promotion && (
                <Banner className={styles.banner} promotion={promotion} onClick={onClickLink} />
              )}
            </div>
          )}
        </div>
      )}
    </DropDownWrapper>
  );
});

DropDown.displayName = 'DropDown';

export default memo(DropDown);
