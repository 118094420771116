import { lazy, memo, Suspense } from 'react';
import { CSSTransition } from 'react-transition-group';

import * as HeaderStore from '@Stores/Header';
import { useLayout } from '@Queries/useLayout';
import useMedias from '@Hooks/useMedias';
import ModalWindowsManager from '@Components/ModalWindowsManager';
import SideBarRedesign from '@Layouts/elems/SideBarRedesign';
import HeaderDesktop from './elems/HeaderDesktop';
import HeaderMobile from './elems/HeaderMobile';
import styles from './Header.module.css';

import type { FC } from 'react';

const SearchPopupContainer = lazy(() => import('@Layouts/elems/SearchPopupContainer'));

const Header: FC = () => {
  const layout = useLayout();
  const { isOnlyMobile, isMobileM } = useMedias();
  const { isOpenedSearch } = HeaderStore.useMenu();

  if (!layout.isSuccess) return null;

  return (
    <>
      {isMobileM ? <HeaderMobile /> : <HeaderDesktop />}

      {isMobileM && (
        <CSSTransition classNames={{ ...styles }} in={isOpenedSearch} timeout={200} unmountOnExit>
          <Suspense fallback={null}>
            <SearchPopupContainer className={styles.searchPopup} compressed />
          </Suspense>
        </CSSTransition>
      )}

      <ModalWindowsManager />

      {isOnlyMobile && <SideBarRedesign />}
    </>
  );
};

export default memo(Header);
