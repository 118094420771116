import { atom, createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { EventListener } from '@Events/EventListener';

import type { CountryCode } from '@Types/Base';

const store = createStore();

const $visitDuration = atom<number>(0);

const $pages = atom<string[]>([]);

const $hasOpenedModals = atom<boolean>(false);

const $lastPage = atom((get) => {
  const pages = get($pages);

  return pages[pages.length - 1] || '';
});

const $dateOfRequest = atomWithStorage<string>(
  'divan.web-push.date-of-request',
  '',
  window.localStorage,
  { getOnInit: true },
);

const $isLessThanDay = atom((get) => {
  const dateOfRequest = new Date((get($dateOfRequest) || '').replaceAll(/"/g, ''));
  const diffBetweenDates = new Date().getTime() - dateOfRequest.getTime();
  const oneDay = 60 * 60 * 24 * 1000;

  return diffBetweenDates < oneDay;
});

export class WebPushListener extends EventListener {
  private intervalId: NodeJS.Timer = null;

  constructor(params: { country: CountryCode }) {
    super();

    // Запускаем только в РФ
    if (['RUS'].includes(params.country)) {
      this.start();
    }
  }

  private start() {
    this.on('page.change', this.onPageChange.bind(this));
    this.on('modalWindows.changeVisibleModals', this.onChangeVisibleModals.bind(this));

    this.intervalId = setInterval(() => {
      store.set($visitDuration, (prev) => prev + 1);
    }, 1000);

    store.sub($visitDuration, this.onChangeStores.bind(this));
    store.sub($pages, this.onChangeStores.bind(this));
  }

  // Проверяем соблюдение условий при изменении заданных значений
  private onChangeStores() {
    const visitDuration = store.get($visitDuration);
    const countOfPages = store.get($pages).length;
    const lastPage = store.get($lastPage);
    const isLessThanDay = store.get($isLessThanDay);
    const hasOpenedModals = store.get($hasOpenedModals);

    // Ничего не делаем, если в последний раз спрашивали разрешение менее суток назад
    if (isLessThanDay) return;

    // Ничего не делаем, если находимся на страницах оформления заказа
    if (lastPage.includes('order/check') || lastPage.includes('order/status')) return;

    // Ничего не делаем, , когда открыто какое-либо модальное окно
    if (hasOpenedModals) return;

    // Запрашиваем доступ к web push при соблюдении следующих условий
    // - длительность визита более 10 сек
    // - или посетил более 2-х страниц
    if (visitDuration > 10 || countOfPages > 2) {
      clearInterval(this.intervalId);

      if (typeof (window as any).mindbox === 'function') {
        // eslint-disable-next-line autofix/no-console
        console.log('webpush.subscribe');
        store.set($dateOfRequest, new Date().toISOString());

        (window as any).mindbox('webpush.subscribe', {
          onGranted: function () {},
          onDenied: function () {},
        });
      }
    }
  }

  // При изменении страницы добавляем её в список посещенных
  // Список хранит только последние 10 страниц
  private onPageChange(event: { pathname: string }) {
    store.set($pages, (prevPages) => {
      const lastPage = store.get($lastPage);

      if (lastPage !== event.pathname) {
        return [...prevPages, event.pathname].slice(-10);
      }

      return prevPages;
    });
  }

  // Изменение списка видимых модальных окон на сайте
  private onChangeVisibleModals(modals: unknown[]) {
    store.set($hasOpenedModals, modals.length > 0);
  }
}
