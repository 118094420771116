import { useMedias } from '@divlab/divanui';

import useOrderState from '@Queries/DeliveryTracking/useOrderState';
import useRouteState from '@Queries/DeliveryTracking/useRouteState';
import useDeps from '@Contexts/DI/useDeps';
import formatDate from '@Utils/formatDate';
import Page404 from '@Pages/Page404';
import DeliveryPageMobile from './elems/DeliveryPageMobile';
import DeliveryPageDesktop from './elems/DeliveryPageDesktop';

import type { FC } from 'react';
import type { DeliveryStatus } from '@Types/DeliveryTracking';

const statuses: DeliveryStatus[] = ['canceled', 'pending', 'completed', 'delivering'];

const DeliveryPage: FC = () => {
  const { data: order } = useOrderState();
  const { data: route } = useRouteState();
  const { isMobile } = useMedias();
  const { logger } = useDeps();
  const status = order?.status;
  let isDeliveryToday = null;

  if (order?.deliveryDate) {
    const deliveryDate = formatDate(new Date(order.deliveryDate));
    const currentDate = formatDate(new Date());

    if (deliveryDate === currentDate) isDeliveryToday = true;
    else isDeliveryToday = false;
  }

  if (!status) return <Page404 />;

  if (!statuses.includes(status)) {
    logger.error(`Unknown delivery status - "${status}"`);

    return <Page404 />;
  }

  if (!order || !route) return null;

  return isMobile ? (
    <DeliveryPageMobile
      order={order}
      route={route}
      isDeliveryToday={isDeliveryToday}
      status={status}
    />
  ) : (
    <DeliveryPageDesktop
      order={order}
      route={route}
      isDeliveryToday={isDeliveryToday}
      status={status}
    />
  );
};

export default DeliveryPage;
