import { useQuery } from '@tanstack/react-query';

import useNavigation from '@Navigation/useNavigation';
import useRequest from '@Hooks/useRequest';
import * as ApiMeta from '@Api/Meta';
import cutOutRegionSlug from '@Utils/cutOutRegionSlug';

/**
 * NOTE: Данный запрос определяет - требуется ли  на текущей странице отобразить анонсную строку. Т.к. для проверки некоторых условий, необходимо передавать cookie,
 * запрос выполняется на стороне клиента, после загрузки основного контента страницы.
 */
function useInfoStripe() {
  const { pathname } = useNavigation();
  const { queryClient, region } = useRequest();

  const keys = ['info-stripe', region, { pathname: cutOutRegionSlug(pathname) }];

  const query = useQuery(
    keys,
    async () => {
      const cache = queryClient.getQueryData<typeof result>(keys);

      if (cache) return Promise.resolve(cache);

      const result = await ApiMeta.getInfoStripe(pathname);
      return result;
    },
    {
      enabled: true,
      keepPreviousData: true,
    },
  );

  return query;
}

export default useInfoStripe;
