import { memo, forwardRef } from 'react';
import { cn, Cross } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import useInfoStripe from '@Queries/InfoStripe/useInfoStripe';
import useCloseInfoStripe from '@Queries/InfoStripe/useCloseInfoStripe';
import CardInView from '@Components/CardInView';
import MenuLeft from './elements/MenuLeft';
import MenuRight from './elements/MenuRight';
import InfoStripe from './elements/InfoStripe';
import styles from './ToplineMenu.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { HTMLAttributes } from 'react';

export interface ToplineMenuProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items?: {
    left: MainMenuItem[];
    right: MainMenuItem[];
  };
}

const ToplineMenu = forwardRef<HTMLDivElement, ToplineMenuProps>((props, ref) => {
  const { className, items, ...restProps } = props;
  const { data: infoStripe } = useInfoStripe();
  const { isOnlyDesktop } = useMedias();
  const closeTopline = useCloseInfoStripe();

  const handleClose = () => {
    closeTopline.mutate();
  };

  return (
    <div
      {...restProps}
      className={cn(
        styles.topline,
        {
          [styles.hasInfoStripe]: !!infoStripe,
        },
        className,
      )}
      ref={ref}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          {isOnlyDesktop &&
            (items?.left ? <MenuLeft className={styles.menuLink} items={items?.left} /> : <div />)}

          {infoStripe && (
            <CardInView
              listTitle='header_banner'
              id={infoStripe.text}
              card={{ link: infoStripe.link, title: infoStripe.text }}
              cardType='promo'
              isSingleCard
              targetPathname={infoStripe.link}
            >
              <InfoStripe infoStripe={infoStripe} className={styles.infoStripe} />
            </CardInView>
          )}

          {isOnlyDesktop && <MenuRight className={styles.menuLink} items={items?.right} />}
        </div>
        {!isOnlyDesktop && (
          <Cross theme='inverse' size='s' className={styles.close} onClick={handleClose} />
        )}
      </div>
    </div>
  );
});

ToplineMenu.displayName = 'ToplineMenu';

export default memo(ToplineMenu);
