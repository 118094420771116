import { useQuery } from '@tanstack/react-query';

import * as ApiCompare from '@Api/Compare';

function useCompareProductsCount() {
  return useQuery(['compare-count'], ApiCompare.getCompareProductsCount, {
    keepPreviousData: true,
  });
}

export default useCompareProductsCount;
