import { useQuery } from '@tanstack/react-query';

import useRequest from '@Hooks/useRequest';
import useDeps from '@Contexts/DI/useDeps';

function useTelephony() {
  const { telephony } = useDeps();
  const request = useRequest();
  const region = request.region || request.mainRegion;
  const keys = ['ctPhone', region];

  const { data } = useQuery(
    keys,
    async () => {
      if (!telephony) return '';

      const phone = await telephony.getPhone(region);

      return phone;
    },
    { keepPreviousData: true },
  );

  return data;
}

export default useTelephony;
