import useRequest from './useRequest';

export interface FeatureFlags {
  /** Is textile sample ordering available in this country? */
  canOrderTextileSamples: boolean;

  /** Is personal area available? */
  personalAreaAvailable: boolean;

  /** Is a mattress quiz is available? */
  mattressQuizAvailable: boolean;

  /** Is a instagram is available? */
  instagramAvailable: boolean;

  /** Do is need to show a link to Suprematika? */
  needLinkToDeveloper: boolean;

  /** Do is need to show a link to PamentSecurityPage? */
  needLinkToPaymentSecurity: boolean;

  /** Is a video consultation is available? */
  videoconsultationAvailable: boolean;

  /** TODO: Временно скрываем поле комментария к заказу, до решения проблемы отправки этого поля в 1С */
  commentFieldAvailable: boolean;

  /** Need load Anti-Flicker script? (used with google optimize service) */
  needAntiFlickerScript: boolean;

  /** Is a credit available?*/
  creditAvailable: boolean;

  /** Is a partial payment available?*/
  partialPaymentAvailable: boolean;

  /** Is multiple languages available?*/
  multipleLangAvailable: boolean;

  /** Display adding fabric samples in the designer? */
  displayingAdditionInConstructor: boolean;

  /** Is a product review from order page available  */
  productCardReviewAvailable: boolean;
}

/**
 * Returns the feature-flags that specified available feature or not
 * For example, in Russia we can order textile samples, but in Belarus this feature unavailable
 * and all components for this feature should be hide or disable
 */
function useFeatureFlags(): FeatureFlags {
  const { country } = useRequest();
  const isRus = country === 'RUS';
  const isBlr = country === 'BLR';
  const isKaz = country === 'KAZ';
  const isUzb = country === 'UZB';

  return {
    canOrderTextileSamples: isRus,
    personalAreaAvailable: isRus || isKaz,
    mattressQuizAvailable: isRus || isKaz || isUzb,
    instagramAvailable: isBlr || isKaz || isUzb,
    needLinkToDeveloper: isRus || isBlr,
    needLinkToPaymentSecurity: isKaz || isUzb,
    videoconsultationAvailable: isRus,
    commentFieldAvailable: false,
    needAntiFlickerScript: false,
    creditAvailable: isRus || isBlr || isKaz || isUzb,
    partialPaymentAvailable: isRus,
    multipleLangAvailable: isUzb || isKaz,
    displayingAdditionInConstructor: false,
    productCardReviewAvailable: false,
  };
}

export default useFeatureFlags;
