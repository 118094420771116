import { memo } from 'react';
import { cn } from '@divlab/divanui';

import CardInView from '@Components/CardInView';
import Link from '@Navigation/Link';
import styles from './Banner.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { ProductsBannerData, Promotion } from '@Promo/typings';

export interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  promotion: Promotion<ProductsBannerData>;
}

const Banner: FC<BannerProps> = (props) => {
  const { className, promotion } = props;
  const banner = promotion.materials[0];
  const { title, subtitle, link } = banner.text;
  const image = banner.resources.default.image;

  return (
    <CardInView
      listTitle='menu'
      card={banner}
      cardType='promo'
      isSingleCard
      id={banner.id}
      targetPathname={banner.link}
    >
      <Link
        className={cn(styles.banner, { [styles.inversed]: banner.inversed }, className)}
        to={banner.link}
        style={{ backgroundImage: `url(${image})` }}
      >
        {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}

        {subtitle && (
          <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}

        {link && <div className={styles.link}>{link}</div>}
      </Link>
    </CardInView>
  );
};

export default memo(Banner);
