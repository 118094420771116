import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { LayoutBurger, LayoutList } from '@Types/Layout';
import type { ApiResponse } from '@Types/Api';

async function getLayout() {
  const url = '/backend/data/layout';
  const res = await Api.queryProxi<ApiResponse<LayoutBurger | LayoutList>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getLayout;
