import { memo, useMemo } from 'react';

import * as HeaderStore from '@Stores/Header';
import styles from './SSRMenuLinks.module.css';

const SSRMenuLinks = () => {
  const { firstLevel } = HeaderStore.useSelectedLevels();
  const { items } = HeaderStore.useMenu();

  const serverLinks = useMemo(() => {
    const secondLvlItems = [];
    const thirdLvlItems = [];
    const fourthLvlItems = [];

    items.forEach((item) => {
      item.children.forEach((item2) => {
        if (item.id !== firstLevel.id) {
          secondLvlItems.push(item2);
        }

        item2.children.forEach((item3) => {
          thirdLvlItems.push(item3);

          item3.children.forEach((item4) => {
            fourthLvlItems.push(item4);
          });
        });
      });
    });

    return [...secondLvlItems, ...thirdLvlItems, ...fourthLvlItems].filter(
      (item) => item.render && item.url,
    );
  }, [items, firstLevel.id]);

  return (
    <div className={styles.links}>
      {serverLinks.map((link) => (
        <a key={link.id} href={link.url} className='MenuLink'>
          {link.name}
        </a>
      ))}
    </div>
  );
};

export default memo(SSRMenuLinks);
