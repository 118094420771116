import { useQuery } from '@tanstack/react-query';

import useDeps from '@Contexts/DI/useDeps';
import * as Profile from '@Queries/Profile';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
export const useOrders = () => {
  const profile = Profile.useProfile();
  const { cabinetApi } = useDeps();
  const keys = ['orders', { mindboxId: profile.data?.mindboxId }];

  const query = useQuery(
    keys,
    async () => {
      const response = await cabinetApi.fetchOrders();

      return response.orders;
    },
    {
      enabled: true,
      keepPreviousData: false,
    },
  );

  return query;
};
