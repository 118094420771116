import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { ParameterValueData } from '@Types/Cart';

export interface OneClickResponse {
  orderId: string;
  name: string;
  phone: string;
  cost: string;
}

export interface ProductData {
  shopProductId: number;
  parameterValues: ParameterValueData[];
  quantity: number;
}

export interface Params {
  name: string;
  phone: string;
  products: ProductData[];
  device: string;
}

async function getOneClickBuy(params: Params) {
  const url = '/backend/order/buy-one-click';
  const res = await Api.queryProxi<ApiResponse<OneClickResponse>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getOneClickBuy;
