import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import useRequest from '@Hooks/useRequest';
import setCookie from '@Utils/setCookie';
import useNavigation from '@Navigation/useNavigation';

import type { UseQueryResult } from '@tanstack/react-query';
import type { AbTestGroup, AbTestOptions, AbTests } from '@Types/AbTests';

export const useAbTests = (options?: AbTestOptions): UseQueryResult<AbTests> => {
  const { search } = useNavigation();
  const { queryClient, region } = useRequest();
  const keys = ['abtests', { region }, options.productId];
  const result = useQuery(
    keys,
    () => {
      const cache = queryClient.getQueryData<AbTests>(keys);
      if (cache) return Promise.resolve(cache);

      return ApiMeta.getAbTests(search, options);
    },
    {
      enabled: true,
      keepPreviousData: true,
      onSuccess: (data) => {
        for (const key in data.groups) {
          const abTestGroup: AbTestGroup = data.groups[key];
          if ('cookie' in abTestGroup) {
            const cookie = abTestGroup.cookie;
            setCookie(cookie.name, cookie.value, { expires: new Date(cookie.expires * 1000) });
          }
        }
      },
    },
  );

  return result;
};

export const useAbTest = (
  abTestName: keyof AbTests['groups'],
  options?: AbTestOptions,
): AbTestGroup => {
  const abTests = useAbTests(options);

  if (!abTests.isSuccess || !abTests.data.groups[abTestName]) return undefined;

  return abTests.data.groups[abTestName];
};

export const useAbTestGroup = (abTestName: keyof AbTests['groups']): string => {
  const abTests = useAbTests();

  if (!abTests.isSuccess || !abTests.data.groups[abTestName]) return undefined;

  return abTests.data.groups[abTestName].group;
};
