import { useQuery } from '@tanstack/react-query';

import * as ApiOrder from '@Api/Order';
import transformCart from '@Queries/Cart/helpers/transformCart';
import useDeps from '@Contexts/DI/useDeps';
import useCartKeys from './useCartKeys';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
function useCart() {
  const keys = useCartKeys();
  const { eventManager } = useDeps();

  const query = useQuery(
    keys,
    async () => {
      const cart = await ApiOrder.getCartInfo();
      return transformCart({ cart: { cart } });
    },
    {
      enabled: true,
      keepPreviousData: true,
      onSuccess: (cart) => {
        eventManager.dispatch('cart.load', cart);
      },
    },
  );

  return query;
}

export default useCart;
