import { memo, useCallback, useState } from 'react';
import { Boldik, cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import useTranslation from '@Queries/useTranslation';
import useLanguages from '@Hooks/useLanguages';
import LangItem from '@Components/Languages/elems/LangItem';
import LanguagesPopup from './elems/LanguagesPopup';
import styles from './LanguageSwitch.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface LanguageSwitchProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const LanguageSwitch: FC<LanguageSwitchProps> = (props) => {
  const { className, ...restProps } = props;
  const { language } = useLanguages();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const handleClick = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <div {...restProps} className={cn(styles.container, className)}>
      <div className={styles.langSwitch} onClick={handleClick}>
        {opened ? (
          <Boldik className={styles.title}>{t('ui.lang.label')}</Boldik>
        ) : (
          <LangItem
            className={styles.title}
            title={language.id.toUpperCase()}
            icon={language.icon}
            selected
          />
        )}
        <IconChevronRight className={styles.iconArrow} />
      </div>

      {opened && <LanguagesPopup onClose={handleClose} />}
    </div>
  );
};

export default memo(LanguageSwitch);
