import { useQuery } from '@tanstack/react-query';

import useSearchParams from '@Hooks/useSearchParams';
import useDeps from '@Contexts/DI/useDeps';
import Api from '@Api/DeliveryTracking';

import type { UseQueryResult } from '@tanstack/react-query';
import type { RouteState } from '@Types/DeliveryTracking';
import type { RouteStateDTO } from '@Api/DeliveryTracking/typings';

const refetchInterval = 10000; // Заменить на 60000

const useRouteState = (): UseQueryResult<RouteState> => {
  const { logger } = useDeps();
  const id = useSearchParams().get('id');

  const keys = ['delivery-route-state', id];

  const getRouteState = async () => {
    try {
      const routeState = await Api.getRouteState({ id });

      return routeState;
    } catch (err) {
      logger.error(err);

      return null;
    }
  };

  const result = useQuery<RouteStateDTO, Error, RouteState>(keys, getRouteState, {
    cacheTime: 0,
    refetchInterval(data) {
      // Ищем в массиве пунктов назначения рейса пункт клиента
      const currentPoint = data?.find((point) => point.type === 'current_client');

      if (!currentPoint?.completed) return refetchInterval; // Заменить на 60000
      return false;
    },
    select(data) {
      if (!data) return [] as RouteState;

      return data.map((item) => ({
        sequence: item.sequence,
        type: item.point_type,
        completed: item.completed,
        coordinates: [item.coordinates.lat, item.coordinates.lon],
      }));
    },
  });

  return result;
};

export default useRouteState;
