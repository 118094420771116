import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';

function usePriorityRegions() {
  const keys = ['regions'];
  const result = useQuery(keys, () => ApiMeta.getRegionPriority(), {
    enabled: true,
  });

  return result;
}

export default usePriorityRegions;
