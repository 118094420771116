import { useQuery } from '@tanstack/react-query';

import ApiSite from '@Api/Site';
import useSearchParams from '@Hooks/useSearchParams';
import generateQueryKeys from '@Queries/helpers/generateQueryKeys';
import useRequest from '@Hooks/useRequest';
import useNavigation from '@Navigation/useNavigation';
import { useDevPanel } from '@Stores/DevPanel';
import useGlobalConfig from '@Hooks/useGlobalConfig';

import type { UseQueryResult } from '@tanstack/react-query';
import type { BasePage } from '@Types/Base';

function usePage<T>(queryKeys: string[] = []): UseQueryResult<T & BasePage> {
  const { queryClient, region } = useRequest();
  const location = useNavigation();

  const { currentDate } = useDevPanel();
  const { isProduction } = useGlobalConfig();
  const searchParams = useSearchParams();

  if (!isProduction) {
    searchParams.set('currentDate', (+currentDate).toString());
  }

  const url = `${location.pathname}?${searchParams}`;
  const keys = generateQueryKeys({
    keys: ['page', region, ...queryKeys],
    pathname: location.pathname,
    search: location.search,
  });

  return useQuery(
    keys,
    () => {
      const cache = queryClient.getQueryData<T & BasePage>(keys);
      if (cache) return Promise.resolve(cache);

      return ApiSite.fetchPage<T & BasePage>({ url });
    },
    {
      enabled: true,
      keepPreviousData: true,
    },
  );
}

export default usePage;
