import type { Manager } from 'client/Events/Manager';
import type { Logger } from '@Infrastructure/entities/logger';
import type { Analytics } from '@Infrastructure/entities/analytics';
import type { Telephony } from '@Infrastructure/entities/telephony';
import type { CabinetApiMethods } from '@Types/Api';

export interface Deps {
  eventManager: Manager;
  logger: Logger;
  cabinetApi: CabinetApiMethods;
  analytics: Analytics;
  telephony: Telephony;
}

class DiContainer {
  private static instance: DiContainer;

  private deps: Deps;

  constructor() {
    if (DiContainer.instance) {
      return DiContainer.instance;
    }
    DiContainer.instance = this;
  }

  initDeps(deps: Deps) {
    this.deps = deps;
  }

  getDeps(): Deps {
    return this.deps;
  }
}

export default new DiContainer();
