import { memo, useCallback, forwardRef } from 'react';
import { cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import Link from '@Navigation/Link';
import styles from './FirstLevelNav.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { HTMLAttributes, MouseEvent } from 'react';

export interface FirstLevelNavProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  fixed?: boolean;
  onChangeItem?: (e: MouseEvent, item: MainMenuItem) => void;
}

const FirstLevelNav = forwardRef<HTMLElement, FirstLevelNavProps>((props, ref) => {
  const { className, fixed, onChangeItem, ...restProps } = props;
  const { items } = HeaderStore.useMenu();
  const { firstLevel, isOpenNewTab } = HeaderStore.useSelectedLevels();

  const handleMouseEnterItem = useCallback(
    (e: MouseEvent, item: MainMenuItem) => {
      if (onChangeItem) onChangeItem(e, item);
    },
    [onChangeItem],
  );

  return (
    <nav {...restProps} className={cn(styles.nav, { [styles.fixed]: fixed }, className)} ref={ref}>
      {items.map((item) => (
        <Link
          className={cn(
            styles.linkWrapper,
            {
              [styles.active]: item.id === firstLevel?.id,
            },
            item.url && 'MenuLink',
          )}
          key={item.id}
          to={item.url}
          ssr={item.render}
          target={isOpenNewTab && '_blank'}
          onMouseEnter={(e) => handleMouseEnterItem(e, item)}
        >
          <span className={styles.link}>{item.name}</span>
        </Link>
      ))}
    </nav>
  );
});

FirstLevelNav.displayName = 'FirstLevelNav';

export default memo(FirstLevelNav);
