import { memo, forwardRef } from 'react';
import { cn, Cross } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import useMedias from '@Hooks/useMedias';
import useInfoStripe from '@Queries/InfoStripe/useInfoStripe';
import useCloseInfoStripe from '@Queries/InfoStripe/useCloseInfoStripe';
import CardInView from '@Components/CardInView';
import Img from '@UI/Img';
import Link from '@UI/Link';
import MenuLeft from './elements/MenuLeft';
import MenuRight from './elements/MenuRight';
import styles from './ToplineMenuWithBanner.module.css';

import type { HTMLAttributes } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface ToplineMenuWithBannerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items?: {
    left: MainMenuItem[];
    right: MainMenuItem[];
  };
}

const ToplineMenuWithBanner = forwardRef<HTMLDivElement, ToplineMenuWithBannerProps>(
  (props, ref) => {
    const { className, items, ...restProps } = props;
    const { fixed } = HeaderStore.useMenu();
    const { data: infoStripe } = useInfoStripe();
    const { isOnlyDesktop } = useMedias();
    const { topLine: topLineBanner } = infoStripe?.banner || {};

    const closeTopline = useCloseInfoStripe();

    const handleClose = () => {
      closeTopline.mutate();
    };

    return (
      <div {...restProps} className={cn(styles.topline, className)} ref={ref}>
        <div className={styles.container}>
          <div className={styles.content}>
            {isOnlyDesktop &&
              (items?.left ? (
                <MenuLeft className={styles.menuLink} items={items?.left} />
              ) : (
                <div />
              ))}

            {topLineBanner && !fixed && (
              <CardInView
                listTitle='headerMenu_banner'
                id={infoStripe.text}
                card={{ link: infoStripe.link, title: infoStripe.text }}
                cardType='promo'
                isSingleCard
                targetPathname={infoStripe.link}
              >
                <div
                  style={{ width: topLineBanner.sizes.width, height: topLineBanner.sizes.height }}
                >
                  <Link to={infoStripe?.link}>
                    <Img src={topLineBanner.image.src} cnImage={styles.banner} />
                  </Link>
                </div>
              </CardInView>
            )}

            {isOnlyDesktop && <MenuRight className={styles.menuLink} items={items?.right} />}
          </div>
          {!isOnlyDesktop && (
            <Cross theme='inverse' size='s' className={styles.close} onClick={handleClose} />
          )}
        </div>
      </div>
    );
  },
);

ToplineMenuWithBanner.displayName = 'ToplineMenu';

export default memo(ToplineMenuWithBanner);
