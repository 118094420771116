import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@UI/Link';
import styles from './PhoneNumber.module.css';

import type { FC } from 'react';
import type { MetaPhoneData } from '@Types/Meta';
import type { LinkProps } from '@Navigation/Link';

export interface PhoneNumberProps extends LinkProps {
  className?: string;
  phone: string | MetaPhoneData;
  mask?: string;
}

function phoneToText(phone: string | MetaPhoneData, mask = ''): string {
  if (typeof phone === 'object') {
    return `+${phone.country} (${phone.code}) ${phone.phone}`;
  }

  const digits = phone.replace(/\D/g, '').split('');

  if (mask.replace(/[^X]/g, '').length === digits.length) {
    return mask.replace(/X/g, () => digits.shift());
  }

  return phone;
}

const PhoneNumber: FC<PhoneNumberProps> = (props) => {
  const { className, phone, mask, ...restProps } = props;

  return (
    <Link className={cn(styles.phoneNumber, className)} {...restProps}>
      {phoneToText(phone, mask)}
    </Link>
  );
};

export default memo(PhoneNumber);
