import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import useRequest from '@Hooks/useRequest';

import type { MetaData } from '@Types/Meta';
import type { UseQueryResult } from '@tanstack/react-query';

function useMeta(): UseQueryResult<MetaData> {
  const { region, secondaryRegion, language, queryClient } = useRequest();
  const keys = ['meta', { region, secondaryRegion, lang: language.id }];

  const query = useQuery(
    keys,
    () => {
      const cache = queryClient.getQueryData<MetaData>(keys);
      if (cache) return Promise.resolve(cache);

      return ApiMeta.fetch();
    },
    {
      enabled: true,
      keepPreviousData: true,
    },
  );

  return query;
}

export default useMeta;
