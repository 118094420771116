import { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import useDeps from '@Contexts/DI/useDeps';
import useChangePositionCount from './useChangePositionCount';

import type { CartPositionData } from '@Types/Cart';
import type { FormEvent } from 'react';

export interface QuantityData {
  quantity: number;
}

export interface Params {
  position: CartPositionData;
}

/**
 * Wrapper for the logic of changing the number of products, allowing optimize the number of backend requests.
 * @param params An object consisting of two fields:
 * `position` - a cart position,
 * @returns A debounced function to change product count on cart
 */
function useOnChangeSetPositionCount(params: Params) {
  const { position } = params;
  const { logger } = useDeps();

  const abortController = useRef<AbortController>(null);
  const changeCount = useChangePositionCount();

  const handleChangeQuantity = useDebouncedCallback((_e: FormEvent, quantityData: QuantityData) => {
    const { quantity } = quantityData;

    try {
      if (abortController.current) {
        abortController.current.abort('abort previous request');
      }

      abortController.current = new AbortController();

      position.products.forEach((product) => {
        changeCount.mutate({
          cartPositionId: position.id,
          quantity,
          productId: product.id,
          signal: abortController.current.signal,
        });
      });
    } catch (err) {
      // Запрос был прерван нами, ошибкой не считаем
      if (err.name === 'AbortError') return;

      logger.log(err);
    }
  }, 400);

  return handleChangeQuantity;
}

export default useOnChangeSetPositionCount;
