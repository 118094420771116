import { useQuery } from '@tanstack/react-query';

import useDeps from '@Contexts/DI/useDeps';
import * as Profile from '@Queries/Profile';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
export const useBonusHistory = () => {
  const { cabinetApi } = useDeps();
  const profile = Profile.useProfile();
  const keys = ['bonus-history', { mindboxId: profile.data?.mindboxId }];

  const query = useQuery(keys, () => cabinetApi.fetchBonusHistory(), {
    enabled: true,
    keepPreviousData: false,
  });

  return query;
};
