import { useRef, memo, useCallback, useState, useEffect } from 'react';
import { cn, Input } from '@divlab/divanui';
import { IconSearch } from '@divlab/divanui/icons';
import { useDebouncedCallback } from 'use-debounce';

import * as SearchStore from '@Stores/Search';
import useTranslation from '@Queries/useTranslation';
import useDeps from '@Contexts/DI/useDeps';
import useMedias from '@Hooks/useMedias';
import styles from './InputSearch.module.css';

import type { InputProps } from '@divlab/divanui';
import type { FC, ChangeEvent, FocusEvent } from 'react';

export interface InputSearchData extends InputProps {
  className?: string;
  autofocus?: boolean;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputSearch: FC<InputSearchData> = (props) => {
  const { autofocus = false, onFocus, ...restProps } = props;
  const { isMobileM } = useMedias();
  const { logger } = useDeps();
  const { result, caretPosition } = SearchStore.useSearch();
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const abortController = useRef<AbortController>(null);
  const { t } = useTranslation();

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) onFocus(e);
      setFocused(true);
    },
    [onFocus],
  );

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const debouceChangeValue = useDebouncedCallback(async (term: string) => {
    try {
      // Отменяем предыдущий запрос
      if (abortController.current) {
        abortController.current.abort('abort previous request');
      }

      abortController.current = new AbortController();

      await SearchStore.search({ term, opts: { signal: abortController.current.signal } });
    } catch (err) {
      // Запрос был прерван нами, ошибкой не считаем
      if (err.name === 'AbortError') return;

      logger.log(err);
    }
  }, 500);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const term = e.target.value;

      if (term.trim().length >= result.minLengthRequest) {
        debouceChangeValue(term);
        SearchStore.setDisabled(false);
      } else {
        debouceChangeValue.cancel();
        SearchStore.setDisabled(true);
      }
    },
    [debouceChangeValue, result.minLengthRequest],
  );

  const handleReset = useCallback(() => {
    debouceChangeValue('');
  }, [debouceChangeValue]);

  useEffect(() => {
    if (!autofocus || !inputRef.current) return;

    inputRef.current.focus();
  }, [inputRef, autofocus]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (!isMobileM) {
      inputRef.current.setSelectionRange(caretPosition, caretPosition);
    }
  }, [caretPosition, isMobileM]);

  return (
    <Input
      {...restProps}
      className={cn(
        styles.input,
        { [styles.active]: !!result.request || focused },
        { [styles.overrider]: true },
      )}
      itemProp='query-input'
      type='input'
      name='search'
      defaultValue={result.request}
      placeholder={t('ui.enter-request')}
      autoComplete='off'
      ref={inputRef}
      before={
        <button type='submit' className={styles.inputSearchIcon} disabled={result.disabled}>
          <IconSearch className={styles.icon} />
        </button>
      }
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onReset={handleReset}
    />
  );
};

export default memo(InputSearch);
