import { cn } from '@divlab/divanui';

import styles from './Burger.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface BurgerProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
  color?: string;
}

export enum BurgerColor {
  WHITE = 'white',
}

const Burger: FC<BurgerProps> = (props) => {
  const { className, color, ...restProps } = props;

  return (
    <button
      {...restProps}
      type='button'
      className={cn(styles.burger, className, {
        [styles.white]: color === 'white',
      })}
      aria-label='Меню'
    />
  );
};

export default Burger;
