import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import * as Profile from '@Queries/Profile';
import useRequest from '@Hooks/useRequest';

import type { LayoutBurger, LayoutList } from '@Types/Layout';
import type { UseQueryResult } from '@tanstack/react-query';

export function useLayout(params: { menuVersion: 'v1' }): UseQueryResult<LayoutList>;
export function useLayout(params: { menuVersion: 'v2' }): UseQueryResult<LayoutBurger>;
export function useLayout(params?: undefined): UseQueryResult<LayoutList | LayoutBurger>;
export function useLayout() {
  const { region, language, queryClient } = useRequest();
  const keys = ['layout', { region, lang: language.id }];
  const profile = Profile.useProfile();
  const isClientDesigner = profile.data?.clientType === 'designer';

  const result = useQuery(
    keys,
    () => {
      const cache = queryClient.getQueryData<LayoutBurger | LayoutList>(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getLayout();
    },
    {
      enabled: true,
      keepPreviousData: true,
    },
  );

  const layout = useMemo(() => {
    const toCustomers = isClientDesigner
      ? result.data.footer.toCustomers.items?.filter((item) => item.text !== 'Divan.Club')
      : result.data.footer.toCustomers.items;

    const footer = {
      ...result.data.footer,
      toCustomers: { ...result.data.footer.toCustomers, items: toCustomers },
    };

    const topLine = isClientDesigner
      ? result.data.topLine?.filter((item) => item.id !== '3039')
      : result.data.topLine;

    return { ...result.data, topLine, footer };
  }, [isClientDesigner, result.data]);

  return { ...result, data: layout } as UseQueryResult<LayoutBurger | LayoutList>;
}
