import { useQuery } from '@tanstack/react-query';

import * as ApiCompare from '@Api/Compare';
import useDeps from '@Contexts/DI/useDeps';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
function useCompareList() {
  const keys = ['compare'];
  const { eventManager } = useDeps();

  const query = useQuery(keys, ApiCompare.getCompareProducts, {
    keepPreviousData: true,
    select(data) {
      return {
        ...data,
        count: data.products?.length,
      };
    },
    onSuccess: (data) => {
      eventManager.dispatch('compareProducts.load', data);
    },
  });

  return query;
}

export default useCompareList;
