import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';
import useRequest from '@Hooks/useRequest';
import useCart from './useCart';

import type { CartData } from '@Types/Cart';

export const useRemovePromocode = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const cart = useCart();

  const { mutate, isLoading } = useMutation(
    async () => {
      const res = await ApiCart.sendPromocode({
        ...cart,
        coupon: null,
        couponData: null,
        couponDiscount: 0,
      });
      return res;
    },
    {
      onSuccess: (NewCart) => {
        queryClient.setQueriesData(keys, (prev: CartData) => {
          return {
            ...prev,
            ...NewCart,
          };
        });
      },
    },
  );

  return {
    removePromocode: mutate,
    isLoading,
  };
};

export default useRemovePromocode;
