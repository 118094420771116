import { useRef, useCallback, useState, memo } from 'react';
import { cn } from '@divlab/divanui';

import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import useTranslation from '@Queries/useTranslation';
import Input from '@UI/Input';
import styles from './Search.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface SearchData extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Search: FC<SearchData> = (props) => {
  const { className, onFocus, onBlur } = props;
  const inputRef = useRef<HTMLInputElement>();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState('');
  const goToSearchResults = SearchStore.useGoToSearchResults();
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    const val = e.target.value;
    setValue(val);
  }, []);

  const handleFocus = useCallback(
    (e) => {
      setFocused(true);

      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (e) => {
      setFocused(false);

      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const term = value || '';

      await SearchStore.search({ term });

      await goToSearchResults(term);

      const input = inputRef.current;
      if (input) {
        input.blur();
      }

      HeaderStore.closeSearch();
    },
    [goToSearchResults, value],
  );

  return (
    <form className={cn(styles.search, className)} action='/' onSubmit={handleSubmit}>
      <Input
        className={styles.input}
        type='input'
        placeholder={t('ui.404.search')}
        ref={inputRef}
        value={value}
        rounded
        before={
          focused ? (
            <div className={styles.iconSearchActive} />
          ) : (
            <div className={styles.iconSearch} />
          )
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </form>
  );
};

export default memo(Search);
