import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { isBrowser } from '@Utils/checkRuntime';
import useDeps from '@Contexts/DI/useDeps';
import useFeatureFlags from '@Hooks/useFeatureFlags';

import type { UseQueryResult } from '@tanstack/react-query';
import type { DesignerProfile, ProfileData } from '@Types/Profile';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
export function useProfile(params: { clientType: 'b2c' }): UseQueryResult<ProfileData>;
export function useProfile(params: { clientType: 'designer' }): UseQueryResult<DesignerProfile>;
export function useProfile(params?: undefined): UseQueryResult<ProfileData | DesignerProfile>;
export function useProfile(params?: { clientType: string }) {
  const { clientType } = params || {};
  const { cabinetApi } = useDeps();
  const { personalAreaAvailable } = useFeatureFlags();
  const keys = ['profile'];

  const query = useQuery(keys, () => cabinetApi.fetchProfile(), {
    enabled: isBrowser && personalAreaAvailable,
    keepPreviousData: true,
  });

  const profile = useMemo(() => {
    if (!query.data) return null;

    // У дизайнер свой личный кабинет и свой формат профиля
    if (clientType === 'designer') {
      const newProfile: DesignerProfile = {
        ...(query.data as DesignerProfile),
        clientType: 'designer',
      };

      return newProfile;
    }

    // Считаем что все остальные b2b-пользователи должны видеть ЛК как B2C
    if (['b2c', 'otherB2b'].includes(clientType)) {
      const newProfile: ProfileData = {
        ...(query.data as ProfileData),
        clientType: 'b2c',
      };

      return newProfile;
    }

    return query.data;
  }, [clientType, query.data]);

  return { ...query, data: profile };
}
