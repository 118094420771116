import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ProductData } from '@Types/Product';

export interface ResultData {
  products: ProductData[];
}

export interface Result {
  ok: 'success' | 'error';
  data: ResultData;
}

async function deleteProduct(id: number, hash?: string) {
  const url = `/backend/compare/v2/${id}`;
  const res = await Api.queryProxi<Result>(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ hash }),
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default deleteProduct;
